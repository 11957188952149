import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Input, Table, Space, message, Spin, Select, Tag, Avatar } from "antd";
import { PlusCircleOutlined, EditOutlined, DatabaseOutlined } from "@ant-design/icons";

const { Option } = Select;

const MantenedorProductos = () => {
  const [productos, setProductos] = useState([]);
  const [form] = Form.useForm();
  const [idProducto, setIdProducto] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [unidadMedida, setUnidadMedida] = useState([]);
  const [tipoInsumos, setTipoInsumos] = useState([]);

  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

   // Cargar tipos de insumos
   const fetchTipoInsumos = async () => {
    try {
      const response = await axios.get("/Service.asmx/GetTipoInsumos", {
        headers: { Authorization: token },
      });

    
      setTipoInsumos(response.data || []);
    } catch (error) {
      message.error("Error al cargar tipos de insumos: " + error.message);
    }
  };

  const fetchUnidadesMedida = async () => {
    try {
      const response = await axios.get("/Service.asmx/GetUnidadMedida", {
        headers: { Authorization: token },
      });
  
     
      setUnidadMedida(response.data || []);
    } catch (error) {
      message.error("Error al cargar unidades de medida: " + error.message);
    }
  };

  // Cargar productos
  const fetchProductos = async () => {
    const userId = localStorage.getItem("userId");
    setIsLoading(true);
    try {
      const response = await axios.get("/Service.asmx/GetProductos", {
        headers: { Authorization: token },
        params: { userId },
      });

      setProductos(response.data || []);
    } catch (error) {
      message.error("Error al cargar productos: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductos();
    fetchUnidadesMedida();
    fetchTipoInsumos();
  }, []);

  const handleSubmitProducto = async (values) => {
    try {
      const endpoint = idProducto
        ? "/Service.asmx/UpdateProducto"
        : "/Service.asmx/InsertProducto";

   
        const data = {
          idProducto: idProducto || 0, // Solo en actualización
          idUsuario: localStorage.getItem("userId"),
          nombre: values.nombre,
          descripcion: values.descripcion,
          idtipoproducto: values.tipo, // 👈 Corregido para coincidir con el servicio
          idunidadMedida: values.unidadMedida, // 👈 Corregido para coincidir con el servicio
          fabricante: values.fabricante || "", // 👈 Se agrega fabricante para que no sea NULL
        };

        
        console.log(data);
       
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data.d
        ? JSON.parse(response.data.d)
        : response.data;

      if (responseData.success) {
        message.success(idProducto ? "Producto actualizado" : "Producto registrado");
        setIsModalOpen(false);
        fetchProductos();
      } else {
        message.error(responseData.message || "Error al guardar el producto.");
      }
    } catch (error) {
      message.error("Error al guardar el producto: " + error.message);
    }
  };

  const handleOpenModal = (producto = null) => {
    if (producto) {
      setIdProducto(producto.idProducto);
      form.setFieldsValue({
        nombre: producto.nombre,
        descripcion: producto.descripcion,
        tipo: tipoInsumos.find((tipo) => tipo.nombre === producto.tipoproducto)?.idtipoinsumo || null,
      unidadMedida: unidadMedida.find((unidad) => unidad.nombre === producto.unidadmedida)?.idunidadmedida || null,
      });
    } else {
      setIdProducto(null);
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const columnsProductos = [
    {
      title: "Nombre",
      key: "insumo",
      render: (_, insumo) => (
        <Space>
          <Avatar icon={<DatabaseOutlined />} /> {/* Ícono representativo */}
          <div>
            <div style={{ fontWeight: "bold" }}>{insumo.nombre}</div>
            <div style={{ color: "gray" }}>{insumo.descripcion}</div>
          </div>
        </Space>
      ),
    },
    { title: "Tipo", dataIndex: "tipoproducto", key: "tipoproducto" },
    { title: "Unidad de Medida", dataIndex: "unidadmedida", key: "unidadmedida" },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, producto) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleOpenModal(producto)}
          >
            Editar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => handleOpenModal()}
      >
        Nuevo Producto
      </Button>
      <Spin spinning={isLoading}>
        <Table
          className="mt-3"
          dataSource={productos}
          columns={columnsProductos}
          rowKey="idProducto"
        />
      </Spin>

      <Modal
        title={idProducto ? "Editar Producto" : "Registrar Producto"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmitProducto}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[{ required: true, message: "La descripción es obligatoria" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="tipo" label="Tipo" rules={[{ required: true, message: "El tipo es obligatorio" }]}>
            <Select
              placeholder="Seleccione un tipo de insumo"
              onChange={(value) => form.setFieldsValue({ tipo: value })}
              allowClear
            >
              {tipoInsumos.map((tipo) => (
                <Option key={tipo.idtipoinsumo} value={tipo.idtipoinsumo}>
                  {tipo.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
  name="unidadMedida"
  label="Unidad de Medida"
  rules={[{ required: true, message: "La unidad de medida es obligatoria" }]}
>
  <Select
    placeholder="Seleccione una unidad de medida"
    onChange={(value) => {
      form.setFieldsValue({ unidadMedida: value }); // Asegura que se registre en el formulario
    }}
    allowClear
  >
    {unidadMedida.map((unidad) => (
      <Option key={unidad.idunidadmedida} value={unidad.idunidadmedida}>
        {unidad.nombre}
      </Option>
    ))}
  </Select>
</Form.Item>

          <Button type="primary" htmlType="submit" block style={{ marginTop: 16 }}>
            {idProducto ? "Actualizar" : "Registrar"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MantenedorProductos;
