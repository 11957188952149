import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Button, Space, Card, Tooltip, DatePicker, Avatar } from 'antd';
import { LeftOutlined, RightOutlined, EnvironmentOutlined, AlertOutlined, InfoCircleOutlined, BarChartOutlined, HourglassOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';

const { Title, Text } = Typography;

const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

const DashboardHome = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [resumen, setResumen] = useState({ total: 0, pendientes: 0, completadas: 0 });
  const [campos, setCampos] = useState([]);

  const handlePrevDay = () => setCurrentDate(prev => moment(prev).subtract(1, 'day'));
  const handleNextDay = () => setCurrentDate(prev => moment(prev).add(1, 'day'));

  const fetchAplicacionesResumen = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get('/Service.asmx/GetAplicaciones', {
        headers: { Authorization: token },
        params: { userId },
      });

      const data = response.data.d ? JSON.parse(response.data.d) : response.data;
      const total = data.length;
      const pendientes = data.filter(app => app.estado === 'PENDIENTE').length;
      const completadas = data.filter(app => app.estado === 'COMPLETADO').length;

      setResumen({ total, pendientes, completadas });
    } catch (error) {
      console.error('Error al obtener resumen:', error);
    }
  };

  const fetchCampos = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get("/Service.asmx/GetCampos", {
        headers: { Authorization: token },
        params: { userId },
      });
      const data = response.data.d ? JSON.parse(response.data.d) : response.data;
      setCampos(data);
    } catch (error) {
      console.error("Error al cargar campos:", error);
    }
  };

  useEffect(() => {
    fetchAplicacionesResumen();
    fetchCampos();
  }, [currentDate]);

  const resumenEstados = [
    { titulo: 'Planificadas', cantidad: resumen.total, icon: <BarChartOutlined style={{ fontSize: 20, color: '#6200EE' }} /> },
    { titulo: 'En Curso', cantidad: resumen.pendientes, icon: <HourglassOutlined style={{ fontSize: 20, color: '#6200EE' }} /> },
    { titulo: 'Listo', cantidad: resumen.completadas, icon: <CheckCircleOutlined style={{ fontSize: 20, color: '#6200EE' }} /> },
    { titulo: 'Campos', cantidad: 0, icon: <EnvironmentOutlined style={{ fontSize: 20, color: '#6200EE' }} /> },
    { titulo: 'Alertas', cantidad: 0, icon: <AlertOutlined style={{ fontSize: 20, color: '#6200EE' }} /> },
  ];

  return (
    <div className="container">
      <Col>
        <h1 className="mb-4">Dashboard</h1>
      </Col>

      <Row align="middle" style={{ marginTop: 10, marginBottom: 20 }}>
        <Space size="middle">
          <Button icon={<LeftOutlined />} onClick={handlePrevDay} />
          <DatePicker
            value={currentDate}
            onChange={setCurrentDate}
            format="DD-MM-YYYY"
            allowClear={false}
          />
          <Button icon={<RightOutlined />} onClick={handleNextDay} />
        </Space>
      </Row>

      <Row gutter={16}>
        {/* 🟣 Columna izquierda scrollable */}
        <Col span={16}>
          <div style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'auto', paddingRight: 10 }}>
            <Title level={4} style={{ marginBottom: 0 }}>Operaciones</Title>

            {/* Cards pequeños */}
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
              {resumenEstados.map((estado, idx) => (
                <Col key={idx}>
                  <Card
                    style={{
                      width: 201,
                      height: 110,
                      borderRadius: 16,
                      backgroundColor: '#fff',
                    }}
                    bodyStyle={{ padding: '16px 12px' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginBottom: 12 }}>
                        <Avatar style={{ backgroundColor: '#EDE7F6' }} size={32}>
                          {estado.icon}
                        </Avatar>
                        <Text strong style={{ fontSize: 14 }}>{estado.titulo}</Text>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <Title level={3} style={{ margin: 1 }}>{estado.cantidad}</Title>
                        <Tooltip title={`Información sobre ${estado.titulo}`}>
                          <InfoCircleOutlined style={{ color: '#999' }} />
                        </Tooltip>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>

            {/* Card de campos */}
            <Title level={4} style={{ marginBottom: 1 }}>Campos</Title>
            <Card style={{ borderRadius: 20, backgroundColor: '#FfFfFf', height:108, marginTop: 10, padding: 12 }}>
  <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', marginTop:-20, marginLeft:-20 }}> {/* Reducí el gap */}
    {campos.map((campo, idx) => (
      <div
        key={idx}
        style={{
          width: 110, // Reducí el ancho para que haya menos separación
          height: 70, // Reduje la altura también
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f2f2f2',
          padding: 4, // Reducí el padding
          borderRadius: 12, // Pequeño ajuste al radio
        }}
      >
        <EnvironmentOutlined style={{ fontSize: 20, color: '#6200EE' }} /> {/* Tamaño más compacto */}
        
          <Text style={{ fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 90 }}>
            {campo.campo}
          </Text>
       
      </div>
     
    ))}
  </div>
</Card>

<Title level={4} style={{ marginBottom: 1 }}>Inventario</Title>

          </div>
        </Col>

        {/* 🔵 Columna derecha fija */}
        <Col span={8}>
          <div style={{ position: 'sticky', top: 100 }}>
            <Card
              title="Cronologia"
              style={{
                borderRadius: 16,
                backgroundColor: '#fff',
              }}
            >
              
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardHome;
