import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    // Simulación de una llamada a la API
    setTimeout(() => {
      setLoading(false);
      message.success(`Se ha enviado un enlace de recuperación a ${values.email}`);
    }, 2000);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          padding: '30px',
          maxWidth: '400px',
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Recuperar Contraseña</h2>
        <Form
          name="forgot_password_form"
          layout="vertical"
          onFinish={onFinish}
        >
          {/* Campo: Email */}
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              { required: true, message: 'Por favor, ingresa tu correo electrónico' },
              { type: 'email', message: 'El correo no es válido' },
            ]}
          >
            <Input
              size="large"
              prefix={<MailOutlined />}
              placeholder="Correo electrónico"
            />
          </Form.Item>

          {/* Botón de Enviar */}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
              style={{ width: '100%' }}
            >
              Recuperar Contraseña
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'center' }}>
          <p>
            ¿Ya recuerdas tu contraseña? <a href="/login">Inicia sesión</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
