import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false); // Indicador de carga
  const navigate = useNavigate();


   // Token de autorización
   const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";


  const onFinish = async  (values) => {
    setLoading(true);
    const { username, password } = values;

    try {
      // Llamada al servicio de validación
      const response = await axios.post(
        "/Service.asmx/UserValidation",
        { mailUser: username, passUser: password },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      // Parsear el campo `d` si está presente
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;

      if (responseData.success) {
        
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem("authToken", "exampleToken"); // Guardar token de sesión
        localStorage.setItem("userName", responseData.nombreUsuario || "Usuario");
        localStorage.setItem("userId", responseData.idUsuario || "idUsuario");
        localStorage.setItem("idCuentaPrincipal", responseData.idCuentaPrincipal || "idCuentaPrincipal");

        navigate("/dashboard"); // Redirigir al Dashboard
      } else {
        message.error("Usuario o contraseña incorrectos");
      }
    } catch (error) {
      message.error("Error al iniciar sesión. Intenta nuevamente.");
    } finally {
      setLoading(false);
    }


  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          padding: '30px',
          maxWidth: '400px',
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Iniciar Sesión</h2>
        <Form
          name="login_form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          {/* Campo: Nombre de usuario */}
          <Form.Item
            name="username"
            rules={[
              { required: true, message: 'Por favor, ingresa tu nombre de usuario' },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder="Nombre de usuario"
            />
          </Form.Item>

          {/* Campo: Contraseña */}
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Por favor, ingresa tu contraseña' },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Contraseña"
            />
          </Form.Item>

          {/* Opciones adicionales */}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Recuérdame</Checkbox>
            </Form.Item>
            <a style={{ float: 'right' }} href="/forgot-password">
              ¿Olvidaste tu contraseña?
            </a>
          </Form.Item>

          {/* Botón de inicio de sesión */}
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={loading}
            >
              Iniciar Sesión
            </Button>
          </Form.Item>
        </Form>

        {/* Enlace para registrarse */}
        <div style={{ textAlign: 'center' }}>
          <p>
            ¿No tienes cuenta? <a href="/register">Regístrate aquí</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
