import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Card, Spin, message, Row, Col, Tag, Avatar, Table, List ,Divider,Modal,Button,Empty   } from "antd";
import axios from "axios";
import {
  ExperimentOutlined,
  CloudOutlined,
  EnvironmentOutlined,
  FireOutlined,UserOutlined, FileTextOutlined ,FileDoneOutlined ,ShoppingCartOutlined,CheckCircleOutlined
} from "@ant-design/icons";

// Función para obtener el icono según el tipo de aplicación
const getApplicationIcon = (tipo) => {
  switch (tipo) {
    case "Fertilización":
      return <ExperimentOutlined style={{ fontSize: "40px", color: "#6200EE" }} />;
    case "Pulverización":
      return <CloudOutlined style={{ fontSize: "40px", color: "#6200EE" }} />;
    case "Irrigación":
      return <EnvironmentOutlined style={{ fontSize: "40px", color: "#6200EE" }} />;
    case "Plantación":
      return <FireOutlined style={{ fontSize: "40px", color: "#6200EE" }} />;
    default:
      return <ExperimentOutlined style={{ fontSize: "40px", color: "#6200EE" }} />;
  }
};





const AplicacionesFormDetails = () => {
  const { idAplicacion } = useParams();
  const [aplicacion, setAplicacion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const token =
    "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

  // Función para cargar los detalles de la aplicación
  const fetchAplicacionDetails = async () => {
    try {
      const response = await axios.get(`/Service.asmx/GetAplicacionDetails`, {
        headers: { Authorization: token },
        params: { idAplicacion },
      });

      const responseData = response.data.d
        ? JSON.parse(response.data.d)
        : response.data;

      console.log(responseData);
      if (responseData && responseData.Aplicacion && responseData.Campos) {
        setAplicacion(responseData);
      } else {
        message.warning("No se encontraron datos para esta aplicación.");
      }
    } catch (error) {
      message.error(
        "Error al cargar los detalles de la aplicación: " + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAplicacionDetails();
  }, [idAplicacion]);

  const completarAplicacion = async () => {
    Modal.confirm({
      title: "Confirmar Completar Aplicación", // 🔹 Título claro
      centered: true, // 🔹 Modal centrado
      icon: null, // 🔹 Quitamos el icono predeterminado
      content: (
        <div style={{ textAlign: "center", padding: "15px", fontSize: "16px", color: "#595959" }}>
          <p>¿Estás seguro de que deseas completar esta aplicación?</p>
          <p style={{ fontSize: "14px", color: "#8c8c8c" }}>
            Esta acción descontará los insumos del inventario y cambiará su estado a completada.
          </p>
        </div>
      ),
      okButtonProps: {
        type: "primary",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          padding: "10px 20px",
        },
      },
      cancelButtonProps: {
        type: "default",
        style: {
          fontSize: "16px",
          padding: "10px 20px",
        },
      },
      okText: "Sí, completar",
      cancelText: "Cancelar",
      onOk: async () => {
        setProcessing(true);
        try {
          const idUsuario = localStorage.getItem("userId");
          const idBodega = aplicacion?.Aplicacion?.idBodega || 1;
          const idAplicacion = aplicacion?.Aplicacion?.idAplicacion;
  
          if (!idAplicacion || !idUsuario || !idBodega) {
            throw new Error("Falta información para completar la aplicación.");
          }
  
          const data = { idAplicacion, idUsuario, idBodega };
  
       
          const response = await axios.post("/Service.asmx/CompletarAplicacion", data, {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          });
  
          const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
  
          if (responseData.success) {
            message.success("✅ Aplicación completada con éxito.");
            fetchAplicacionDetails(); // Recargar datos para reflejar cambios
          } else {
            throw new Error(responseData.message || "No se pudo completar la aplicación.");
          }
        } catch (error) {
          mostrarErrorModal(error.message);
        } finally {
          setProcessing(false);
        }
      },
    });
  };
  
  // Función para mostrar el modal de error con mensaje del servicio
  const mostrarErrorModal = (mensaje) => {
    Modal.error({
      title: "Error al Completar Aplicación",
      centered: true,
      content: (
        <div style={{ textAlign: "center", padding: "15px", fontSize: "16px", color: "#595959" }}>
          <p style={{ fontWeight: "bold", color: "#D32F2F" }}>{mensaje}</p>
          <p style={{ fontSize: "14px", color: "#8c8c8c" }}>
            Verifica el stock disponible o la información de la aplicación antes de intentarlo de nuevo.
          </p>
        </div>
      ),
      okText: "Aceptar",
      okButtonProps: {
        type: "primary",
        danger: true, // 🔹 Rojo para indicar error
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          padding: "10px 20px",
        },
      },
    });
  };
  
  

  // 🔥 Calcular la suma total de hectáreas
  const totalHectareas = aplicacion?.Campos?.reduce((sum, campo) => sum + campo.cantidadHectareas, 0) || 0;

// 🔹 Definir las columnas para la tabla de Productos
const productosColumns = [
  {
    title: "Producto",
    dataIndex: "producto",
    key: "producto",
    render: (producto) => (
      <span style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
        {producto}
      </span>
    ),
  },
  {
    title: "Cantidad por Hectárea",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "center",
    render: (cantidad, record) => (
      <span style={{ fontWeight: "bold", color: "#333" }}>
        {new Intl.NumberFormat("es-ES").format(cantidad)} {record.unidadMedida}/Ha
      </span>
    ),
  },
  {
    title: "Total Aplica",
    key: "totalAplica",
    align: "center",
    render: (_, record) => {
      const totalAplicacion = record.cantidad * totalHectareas;
      return (
        <span style={{ fontWeight: "bold", color: "#333" }}>
          {new Intl.NumberFormat("es-ES").format(totalAplicacion)} {record.unidadMedida}
        </span>
      );
    },
  },
];

   // Definir las columnas para la tabla de campos
   const camposColumns = [
    {
      title: "Campo",
      dataIndex: "campo",
      key: "campo",
      render: (campo) => <span style={{ fontWeight: "bold" }}>{campo}</span>,
    },
    {
      title: "Cultivo",
      dataIndex: "cultivo",
      key: "cultivo",
      render: (cultivo) => (
        <span style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
       
          {cultivo}
        </span>
      ),
    },
    {
      title: "Hora de Aplicación",
      key: "horaAplicacion",
      render: (_, record) => (
        <span style={{ fontWeight: "bold", color: "#333" }}>
          de {record.horainicio} hasta {record.horafin}
        </span>
      ),
    },
    {
      title: "Total Horas",
      key: "horaAplicacion",
      render: (_, record) => (
        <span style={{ fontWeight: "bold", color: "#333" }}>
          {record.horatrabajo} 
        </span>
      ),
    },
    {
      title: "Area de Trabajo",
      dataIndex: "cantidadHectareas",
      key: "cantidadHectareas",
      align: "center",
      render: (cantidad) => (
        <span style={{ fontWeight: "bold", color: "#333" }}>{cantidad} Ha</span>
      ),

    },

   

  ];




   
// 🔥 Calcular la suma total de productos

  const totalCantidadAplica =
  aplicacion?.Productos?.reduce(
    (sum, producto) => sum + (producto.cantidad * totalHectareas || 0),
    0
  ) || 0;


  return (
    <div style={{ padding: "20px", borderRadius: "8px" }}>
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/aplicaciones">Operaciones Periódicas</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detalle de Operación</Breadcrumb.Item>
      </Breadcrumb>

      {loading ? (
        <Spin size="large" />
      ) : aplicacion ? (
        <>
          {/* Primer Card con tipo de aplicación y estado */}
          <Card
            style={{
              width: "100%",
              borderRadius: "26px",
                           marginBottom: "16px",
                               height: "120px", // 🔥 Ajusta la altura del Card
    
            }}
          >
            <Row align="middle">
              {/* Columna del ícono */}
              <Col span={2} style={{ display: "flex", alignItems: "flex-start" }}>
                <Avatar
                  size={64}
                  style={{ backgroundColor: "#EDE7F6", color: "#6200EE" }}
                >
                  {getApplicationIcon(aplicacion.Aplicacion.tipoAplicacion)}
                </Avatar>
              </Col>

              {/* Columna de la descripción */}
              <Col span={22} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                {/* Tipo de aplicación y estado en la misma línea */}
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <h1 style={{ margin: 0 }}>{aplicacion.Aplicacion.tipoAplicacion}</h1>
                  <Tag
                    color={aplicacion.Aplicacion.estado === "P" ? "gold" : "green"}
                    style={{
                      fontSize: "12px",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    {aplicacion.Aplicacion.estado === "P" ? "PENDIENTE" : "COMPLETADO"}
                  </Tag>
                </div>

                {/* Fecha programada debajo */}
                
                <Row style={{ display: "flex", alignItems: "center", gap: "12px", marginTop: "4px" }}>
        {/* Fecha Programada */}
        <Col>
          <p style={{ fontWeight: "bold", color: "gray", fontSize: "15px", margin: 0 }}>
            Planificación para: {aplicacion.Aplicacion.fechaProgramada}
          </p>
        </Col>

        {/* Método de Aplicación */}
        <Col>
          <p style={{ fontWeight: "bold", color: "gray", fontSize: "15px", margin: 0 }}>
            Método de Apliación: {aplicacion.Aplicacion.metodoAplicacion}
          </p>
        </Col>
      </Row>
              </Col>
            </Row>
          </Card>
        {/* Card con íconos y datos alineados */}


        <h2 style={{marginBottom:"20px" }}>Información General</h2>

        <Card
  style={{
    width: "100%",
    borderRadius: "26px",
    padding: "16px",
    height: "auto", // Ajuste automático según contenido
  }}

  
>


  <Row align="middle" style={{ justifyContent: "space-between" }}>
    {/* 🔹 Nombre descriptivo */}
    <Col span={4} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <FileDoneOutlined style={{ fontSize: "34px", color: "#6200EE" }} />
      <div>
        <p style={{ fontSize: "12px", fontWeight: "bold", color: "#333", margin: 0 }}>Nombre Descriptivo</p>
        <p style={{ fontSize: "16px", color: "#595959", margin: 0 }}>{aplicacion.Aplicacion.descripcion}</p>
      </div>
    </Col>

  {/* 🔹 Cultivo */}
<Col span={4} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  <EnvironmentOutlined style={{ fontSize: "34px", color: "#6200EE" }} />
  <div>
    <p style={{ fontSize: "12px", fontWeight: "bold", color: "#333", margin: 0 }}>Cultivo</p>
    <div style={{ display: "flex", gap: "6px", flexWrap: "nowrap" }}>
      {Array.from(new Set(aplicacion?.Campos?.map(c => c.cultivo)))
        .slice(0, 2) // Tomar los dos primeros cultivos
        .map((cultivo, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              padding: "4px 8px",
              
            }}
          >
          
            <span style={{ fontSize: "12px", fontWeight: "bold", color: "#333" }}>
              {cultivo}
            </span>
          </div>
        ))}

      {/* 🔹 Mostrar "+X" si hay más de 2 cultivos */}
      {Array.from(new Set(aplicacion?.Campos?.map(c => c.cultivo))).length > 2 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "4px 10px",
           
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
            minWidth: "45px",
          }}
        >
          +{Array.from(new Set(aplicacion?.Campos?.map(c => c.cultivo))).length - 2}
        </div>
      )}
    </div>
  </div>
</Col>



  {/*   🔹 Campos
    <Col span={4} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <EnvironmentOutlined style={{ fontSize: "34px", color: "#588157" }} />
      <div>
        <p style={{ fontSize: "12px", fontWeight: "bold", color: "#333", margin: 0 }}>Campos</p>
        <div style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "6px",
          border: "1px solid #d9d9d9",
          borderRadius: "12px",
          padding: "4px 8px",
        }}>

          <span style={{ fontSize: "14px", color: "#333", fontWeight: "bold" }}></span>
        </div>
      </div>
    </Col> */}

  

    {/* 🔹 Asignado a */}
    <Col span={5} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <UserOutlined style={{ fontSize: "34px", color: "#6200EE" }} />
      <div>
        <p style={{ fontSize: "12px", fontWeight: "bold", color: "#333", margin: 0 }}>Responsable</p>
        <p style={{ fontSize: "16px", color: "#595959", margin: 0 }}>{aplicacion.Aplicacion.responsable}</p>
      </div>
    </Col>
  </Row>
</Card>


<Row   >

<h2 style={{marginBottom:"1px" }}>Campos</h2>

<div style={{ width: "100%",marginBottom:"1px" }} >
  
  <Table
    
    columns={camposColumns}
    dataSource={aplicacion.Campos}
    pagination={false} // 🔥 Sin paginación
   
    locale={{
      emptyText: (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} // 🔹 Mantiene el icono predeterminado
          description="No hay datos disponibles"
        />
      ),
    }}
    style={{
    width: "100%",
    borderRadius: "26px", // 🔥 Bordes curvos generales
    overflow: "hidden", // 🔥 Evita que los bordes se corten
    marginTop:"20px"  
    , background: "#f0f2f5" 
  }}
    summary={() => (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2} align="right">
          <strong>Total Hectáreas:</strong>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="right">
          <strong>{totalHectareas} Ha</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )}
  />
 
</div>
</Row>

<h2 style={{marginBottom:"1px" }}>Insumos</h2>
<Row   >
<div style={{ width: "100%" }} >
{/* 🔹 Tabla de Productos */}
<Table
            columns={productosColumns}
            dataSource={aplicacion.Productos}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE} // 🔹 Mantiene el icono predeterminado
                  description="No hay datos disponibles"
                />
              ),
            }}
            style={{
              width: "100%",
              borderRadius: "26px",
              overflow: "hidden",
              marginTop: "20px"
              , background: "#f0f2f5" 
            }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1} align="right">
                  <strong></strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                  <strong>Total a aplicar</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                <strong>{totalCantidadAplica}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />

</div>
</Row>

 {/* Botón para completar la aplicación */}
 <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              size="large"
              loading={processing}
              disabled={aplicacion.Aplicacion.estado === "C"} // 🔹 Si está completada, el botón se desactiva
              onClick={completarAplicacion}
              style={{
                
                fontWeight: "bold",
              }}
            >
              {aplicacion.Aplicacion.estado === "C" ? "Aplicación Completada" : "Completar Aplicación"}
            </Button>
          </div>
        </>
      ) : (
        <p>No se encontraron datos para esta aplicación.</p>
      )}
    </div>
  );
};

export default AplicacionesFormDetails;
