import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Input, Table, Space, message, Spin } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";

const MantenedorCultivos = () => {
  const [cultivos, setCultivos] = useState([]);
  const [form] = Form.useForm();
  const [idCultivo, setIdCultivo] = useState(null); // ID del cultivo en edición
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

  // Función para cargar los cultivos
  const fetchCultivos = async () => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get("/Service.asmx/GetCultivos", {
        headers: { Authorization: token },
        params: { userId },
      });

      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;

      if (Array.isArray(responseData)) {
        setCultivos(responseData);
      } else {
        message.error("No se encontraron cultivos registrados.");
      }
    } catch (error) {
      message.error("Error al cargar cultivos: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCultivos();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const userId = localStorage.getItem("userId");
      const endpoint = idCultivo
        ? "/Service.asmx/UpdateCultivo"
        : "/Service.asmx/InsertCultivo";

      const data = {
        idUsuario: parseInt(userId, 10),
        idCultivo: idCultivo || null,
        nombre: values.nombre,
      };

      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;

      if (responseData.success) {
        message.success(idCultivo ? "Cultivo actualizado con éxito." : "Cultivo registrado con éxito.");
        setIsModalOpen(false);
        fetchCultivos();
        form.resetFields();
        setIdCultivo(null);
      } else {
        message.error(responseData.message || "Error al guardar el cultivo.");
      }
    } catch (error) {
      message.error("Error al guardar el cultivo: " + error.message);
    }
  };

  const handleOpenModal = (cultivo = null) => {
    if (cultivo) {
      setIdCultivo(cultivo.idCultivo);
      form.setFieldsValue({ nombre: cultivo.nombre });
    } else {
      setIdCultivo(null);
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const columnsCultivos = [
    { title: "Nombre", dataIndex: "nombre", key: "nombre" },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, cultivo) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleOpenModal(cultivo)}>
            Editar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      

      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => handleOpenModal()}
      >
        Nuevo Cultivo
      </Button>

      <Spin spinning={isLoading}>
        <Table
          className="mt-4"
          dataSource={cultivos}
          columns={columnsCultivos}
          rowKey="idCultivo"
        />
      </Spin>

      <Modal
        title={idCultivo ? "Editar Cultivo" : "Registrar Cultivo"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre del cultivo es obligatorio." }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            {idCultivo ? "Actualizar" : "Registrar"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MantenedorCultivos;
