import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Input, Table, Space, message, Spin, Select, Tag, Avatar } from "antd";
import { PlusCircleOutlined, EditOutlined, HomeOutlined } from "@ant-design/icons";

const { Option } = Select;

const Bodega = () => {
  const [bodegas, setBodegas] = useState([]);
  const [form] = Form.useForm();
  const [idBodega, setIdBodega] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoInsumos, setTipoInsumos] = useState([]);
  const [selectedTipoInsumos, setSelectedTipoInsumos] = useState([]);

  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

  // Cargar tipos de insumos
  const fetchTipoInsumos = async () => {
    try {
      const response = await axios.get("/Service.asmx/GetTipoInsumos", {
        headers: { Authorization: token },
      });
      setTipoInsumos(response.data || []);
    } catch (error) {
      message.error("Error al cargar tipos de insumos: " + error.message);
    }
  };

  // Cargar bodegas
  const fetchBodegas = async () => {
    const userId = localStorage.getItem("userId");
    setIsLoading(true);
    try {
      const response = await axios.get("/Service.asmx/GetBodegas", {
        headers: { Authorization: token },
        params: { userId },
      });

      // Formatear los tipos de insumo para que sean un array separado por "/"
      const bodegasData = response.data.map((bodega) => ({
        ...bodega,
        tipoInsumos: bodega.tipoinsumo ? bodega.tipoinsumo.split(" / ") : [],
      }));

      setBodegas(bodegasData);
    } catch (error) {
      message.error("Error al cargar bodegas: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBodegas();
    fetchTipoInsumos();
  }, []);

  const handleSubmitBodega = async (values) => {
    try {
      const endpoint = idBodega
        ? "/Service.asmx/UpdateBodegaYTipoInsumo" // Ahora usa la ruta correcta para actualizar
        : "/Service.asmx/InsertarBodegaYTipoInsumo";
  
      const data = {
        idBodega: idBodega || 0, // Si es nuevo, `idBodega` será 0 (para evitar errores en el servicio)
        idUsuario: parseInt(localStorage.getItem("userId"), 10),
        nombreBodega: values.nombre,
        ubicacion: "", 
        insumos: selectedTipoInsumos.map((idTipoInsumo) => ({
          idTipoInsumo: parseInt(idTipoInsumo, 10), // Asegurar que el servicio reciba números
        })),
      };
  
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
  
      const responseData = response.data.d
        ? JSON.parse(response.data.d)
        : response.data;
  
      if (responseData.success) {
        message.success(idBodega ? "Bodega actualizada" : "Bodega registrada");
        setIsModalOpen(false);
        fetchBodegas(); // Recargar lista de bodegas después de actualizar
      } else {
        message.error(responseData.message || "Error al guardar la bodega.");
      }
    } catch (error) {
      message.error("Error al guardar la bodega: " + error.message);
    }
  };

  const handleOpenModal = (bodega = null) => {
    if (bodega) {
      setIdBodega(bodega.idBodega);
      form.setFieldsValue({
        nombre: bodega.nombre,
      });
  
      // Convertir `tipoinsumo` en array separando por " / " y eliminando espacios extra
      const insumosBodega = bodega.tipoinsumo
        ? [...new Set(bodega.tipoinsumo.split(" / ").map(tipo => tipo.trim().toLowerCase()))] // Normalizar nombres
        : [];
  
      console.log("Insumos Bodega (depuración):", insumosBodega);
      console.log("Lista completa de TipoInsumos (depuración):", tipoInsumos);
  
      // Buscar los IDs correspondientes en `tipoInsumos`
      const insumosIds = tipoInsumos
        .filter(tipo => insumosBodega.includes(tipo.nombre.trim().toLowerCase())) // Comparar nombres normalizados
        .map(tipo => String(tipo.idtipoinsumo)); // Convertir a string para Select
  
      console.log("IDs Insumos Seleccionados (depuración):", insumosIds);
  
      setSelectedTipoInsumos(insumosIds);
  
      // También actualizar el formulario para reflejar los valores
      form.setFieldsValue({
        tipoInsumos: insumosIds,
      });
  
    } else {
      setIdBodega(null);
      form.resetFields();
      setSelectedTipoInsumos([]);
    }
    setIsModalOpen(true);
  };
  


  // Definición de columnas para la tabla de bodegas
  const columnsBodegas = [
    {
      title: "Nombre",
      key: "bodega",
      render: (_, bodega) => (
        <Space>
          <Avatar icon={<HomeOutlined />} />
          <div>
            <div>{bodega.nombre}</div>
          </div>
        </Space>
      ),
    },
    {
      title: "Tipos de Insumo",
      dataIndex: "tipoInsumos",
      key: "tipoInsumos",
      render: (tipoInsumos) =>
        tipoInsumos?.length ? (
          tipoInsumos.map((tipo) => (
            <Tag key={tipo} >
              {tipo}
            </Tag>
          ))
        ) : (
          <span style={{ color: "default" }}>Sin asignar</span>
        ),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, bodega) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleOpenModal(bodega)}>
            Editar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => handleOpenModal()}
      >
        Nueva Bodega
      </Button>
      <Spin spinning={isLoading}>
        <Table
          className="mt-3"
          dataSource={bodegas}
          columns={columnsBodegas}
          rowKey="idBodega"
        />
      </Spin>

      {/* Modal de Registro/Edición */}
      <Modal
        title={idBodega ? "Editar Bodega" : "Registrar Bodega"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmitBodega}>
          <Form.Item
            name="nombre"
            label="Nombre de la Bodega"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
  name="tipoInsumos"
  label="Tipos de Insumo"
  rules={[{ required: true, message: "Seleccione al menos un tipo de insumo" }]}
>
  <Select
    mode="multiple"
    placeholder="Seleccione tipos de insumo"
    value={selectedTipoInsumos} // Usar IDs aquí
    onChange={(value) => {
      setSelectedTipoInsumos(value);
      form.setFieldsValue({ tipoInsumos: value }); // Asegurar que el Form se actualice
    }}
  >
    {tipoInsumos.map((tipo) => (
      <Option key={String(tipo.idtipoinsumo)} value={String(tipo.idtipoinsumo)}>
        {tipo.nombre} {/* Muestra el nombre en la lista */}
      </Option>
    ))}
  </Select>
</Form.Item>
          <Button type="primary" htmlType="submit" block style={{ marginTop: 16 }}>
            {idBodega ? "Actualizar" : "Registrar"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Bodega;
