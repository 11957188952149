import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Select, Input, message, Spin, Row, Col, Tabs, Steps, DatePicker,Card,Typography } from "antd";
import { PlusOutlined, DatabaseOutlined,BarsOutlined,MinusCircleOutlined,DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;
const { Step } = Steps;

const AlmacenesForm = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bodegas, setBodegas] = useState([]);
  const [inventario, setInventario] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredInventario, setFilteredInventario] = useState([]);
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [proveedores, setProveedores] = useState([]);
  const [newProveedor, setNewProveedor] = useState({ nombre: ""});
  const [isProveedorModalOpen, setIsProveedorModalOpen] = useState(false);
  const [insumos, setInsumos] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [productos, setProductos] = useState([{ idProducto: null, cantidad: "", precioUnidad: "" }]); // 🔹 Estado para múltiples productos
  const [movimientos, setMovimientos] = useState([]);


  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";


  const fetchInsumos = async () => {
    const userId = localStorage.getItem("userId"); // Obtener userId
    try {
      const response = await axios.get("/Service.asmx/GetProductos", {
        headers: { Authorization: token },
        params: { userId },
      });
  
 

      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setInsumos(responseData || []); // Guardar insumos en el estado
     
    } catch (error) {
      message.error("Error al cargar los insumos: " + error.message);
    }
  };
  

   // 🔹 Función para manejar el avance entre pasos
   const handleNext = () => {
    form
      .validateFields()
      .then(() => setCurrentStep(currentStep + 1))
      .catch((err) => console.log("Errores en el formulario:", err));
  };


  const handlePrev = () => setCurrentStep(currentStep - 1);


  // 🔹 Cargar las bodegas disponibles
  const fetchBodegas = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get("/Service.asmx/GetBodegas", {
        headers: { Authorization: token },
        params: { userId },
      });

      const bodegasData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setBodegas(bodegasData);
    } catch (error) {
      message.error("Error al cargar las bodegas: " + error.message);
    }
  };

  const fetchProveedores = async () => {
    try {
      const userId = localStorage.getItem("userId");
  
      if (!userId) {
        message.error("Error: No se encontró el ID del usuario.");
        return;
      }

  
      const response = await axios.get("/Service.asmx/GetProveedores", {
        headers: { Authorization: token },
        params: { userId: parseInt(userId, 10) }, // Enviar el ID del usuario como parámetro
      });
  
     
      const proveedoresData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setProveedores(proveedoresData);
    } catch (error) {
      message.error("Error al cargar los proveedores: " + error.message);
    }
  };


  const fetchMovimientos = async (bodegaId) => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get("/Service.asmx/GetMovimientosInventario", {
        headers: { Authorization: token },
        params: { userId, bodegaId },
      });

      const movimientosData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setMovimientos(movimientosData);
    } catch (error) {
      message.error("Error al cargar los movimientos: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };


  // 🔹 Cargar inventario por usuario y bodega seleccionada
  const fetchInventario = async (bodegaId) => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get("/Service.asmx/GetInventarioBodega", {
        headers: { Authorization: token },
        params: { userId, bodegaId },
      });

      const inventarioData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setInventario(inventarioData);
      setFilteredInventario(inventarioData);
    } catch (error) {
      message.error("Error al cargar el inventario: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBodegas();
    fetchProveedores();
    fetchInsumos();
  }, []);

  const handleSubmit = async () => {
    const userId = localStorage.getItem("userId");
    const values = form.getFieldsValue(true);
  
    if (!values.bodega || !values.proveedor || !values.fechaIngreso) {
      message.warning("Debe completar la bodega, proveedor y fecha de ingreso.");
      setCurrentStep(0);
      return;
    }
  
    if (productos.length === 0 || productos.some(p => !p.idProducto || !p.cantidad || !p.precioUnidad)) {
      message.warning("Debes completar todos los detalles de los productos.");
      return;
    }
  
    for (const producto of productos) {
      const data = {
        idUsuario: parseInt(userId, 10),
        idBodega: values.bodega,
        fechaMov: moment().format("YYYY-MM-DD"),
        tipoMovimiento: "Ingreso",
        cantidad: parseFloat(producto.cantidad),
        idProducto: producto.idProducto,
        origenMovimiento: "Manual",
        idAplicacion: null,
        idProveedor: values.proveedor,
        numeroReferencia: values.numeroReferencia || "",
        fechaIngreso: values.fechaIngreso
          ? values.fechaIngreso.format("YYYY-MM-DD")
          : null,
        fechaProduccion: producto.fechaProduccion
          ? producto.fechaProduccion.format("YYYY-MM-DD")
          : null,
        fechaVencimiento: producto.fechaVencimiento
          ? producto.fechaVencimiento.format("YYYY-MM-DD")
          : null,
        precioUnitario: parseFloat(producto.precioUnidad),
      };
  
     


      try {
        const response = await axios.post(
          "/Service.asmx/InsertMovimientosInventario",
          JSON.stringify({ ...data }),
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
  
        const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
        
        if (!responseData.success) {
          message.error(responseData.message || "Error al guardar un producto.");
          return;
        }
  
      } catch (error) {
        message.error("Error en la petición: " + error.message);
        return;
      }
    }
  
    message.success("Productos registrados con éxito.");
    form.resetFields();
    setProductos([{ idProducto: null, cantidad: "", precioUnidad: "" }]);
    setIsModalOpen(false);
    fetchInventario(values.bodega);
  };
  

  const openModal = () => {
    form.resetFields(); // 🔹 Asegura que los valores previos no interfieran
    setIsModalOpen(true);
  };

   // 🔹 Agregar un nuevo producto a la lista
   const handleAddProducto = () => {
    setProductos([...productos, { idProducto: null, cantidad: "", precioUnidad: "" }]);
  };


   // 🔹 Manejo del cambio en los campos de productos
   const handleProductoChange = (index, field, value) => {
    const updatedProductos = [...productos];
    updatedProductos[index][field] = value;
    setProductos(updatedProductos);
  };
  // 🔹 Eliminar un producto de la lista
  const handleRemoveProducto = (index) => {
    setProductos(productos.filter((_, i) => i !== index));
  };

  // 🔹 Filtrar productos por búsqueda
  const handleSearch = (value) => {
    const lowercasedValue = value.toLowerCase();
    const filteredData = inventario.filter((item) =>
      item.producto.toLowerCase().includes(lowercasedValue)
    );
    setFilteredInventario(filteredData);
  };

  const handleSubmitProveedor = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!newProveedor.nombre) {
        message.warning("Por favor, ingresa el nombre del proveedor.");
        return;
      }

      const data = {
        idUsuario: parseInt(userId, 10),
        nombre: newProveedor.nombre,
      };

      const response = await axios.post("/Service.asmx/InsertProveedor", data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;

      if (responseData.success) {
        message.success("Proveedor registrado con éxito.");
        fetchProveedores(); // Recargar proveedores
        setIsProveedorModalOpen(false);
        setNewProveedor({ nombre: "" });
      } else {
        message.error(responseData.message || "Error al guardar el proveedor.");
      }
    } catch (error) {
      message.error("Error al guardar el proveedor: " + error.message);
    }
  };

  return (
    <div className="container" style={{ padding: "20px" }}>

<Row justify="space-between" align="middle">

        <Col>
          <h1 className="mb-4">Gestión de Almacenes</h1>
        </Col>

       <Col>
         <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
           Nuevo Registro
         </Button>
       </Col>

</Row>

<Row style={{ marginBottom: "20px" }}>
    <Col>
      <Select
        placeholder="Seleccione una bodega"
        style={{ width: 250 }}
        onChange={(value) => {
          setSelectedBodega(value);
          fetchInventario(value);
          fetchMovimientos(value);
        }}
      >
        {bodegas.map((bodega) => (
          <Option key={bodega.idBodega} value={bodega.idBodega}>
            {bodega.nombre}
          </Option>
        ))}
      </Select>
    </Col>
   
  </Row>

 

<Tabs defaultActiveKey="1" type="card" style={{ height: "100%" }}>
        {/* 🟢 TAB INVENTARIO */}
        <TabPane tab={<span> Inventario</span>} key="1">

      

      {/* Selector de Bodega y Búsqueda */}
      <Row justify="start" gutter={16} style={{ marginBottom: "30px" }}> {/* 🔹 Aumentado marginBottom */}
       

        <Col>
          <Input.Search
            placeholder="Buscar producto..."
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 250 }}
          />
        </Col>
      </Row>

      {/* Tabla de Inventario - Expande al Ancho Completo */}
      <Row>
        <Col span={24}>
          {isLoading ? (
            <Spin tip="Cargando inventario..." />
          ) : (
            <Table
             borderRadius= "26px"
            className="mt-3"
            size="small" 
              dataSource={filteredInventario}
              columns={[
                {
                  title: "Producto",
                  dataIndex: "producto",
                  key: "producto",
                  render: (text) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                     
                      {text}
                    </div>
                  ),
                },
                { title: "Cantidad", dataIndex: "cantidad", key: "cantidad", align: "center" },
                { title: "Tipo", dataIndex: "tiposinsumo", key: "tiposinsumo", align: "center" },
                { title: "Unidad de Medida", dataIndex: "unidadmedida", key: "unidadmedida", align: "center" },
              ]}
              components={{
                header: {
                  cell: (props) => (
                    <th {...props} style={{ fontSize: "16px", padding: "18px", height: "50px", background: "#f0f2f5" }} />
                  ),
                },
              }}
              rowKey="idProducto"
              pagination={{ pageSize: 10 }}
              scroll={{ x: "100%" }} // 🔥 Expande la tabla al ancho completo
              bordered
            />
          )}
        </Col>
      </Row>
      </TabPane>

      <TabPane tab={<span> Movimientos</span>} key="2">
        

          <Row>
            <Col span={24}>
              {isLoading ? (
                <Spin tip="Cargando movimientos..." />
              ) : (
                <Table
                borderRadius= "26px"
                className="mt-3 table-no-vertical-lines"          
                size="small" 
                  dataSource={movimientos}
                  columns={[
                    { title: "Producto", dataIndex: "producto", key: "producto" },
                    { title: "Cantidad", dataIndex: "cantidad", key: "cantidad", align: "center" },
                    { title: "Proveedor", dataIndex: "proveedor", key: "proveedor" },
                    { title: "Referencia", dataIndex: "numeroReferencia", key: "numeroReferencia" },
                    { title: "Producción", dataIndex: "fechaproduccion", key: "fechaproduccion" },
                    { title: "Vencimiento", dataIndex: "fechavencimiento", key: "fechavencimiento" },
                    { title: "Precio Unitario", dataIndex: "precioUnitario", key: "precioUnitario", align: "right" },
                  ]}
                  components={{
                    header: {
                      cell: (props) => (
                        <th {...props} style={{ fontSize: "16px", padding: "18px", height: "50px", background: "#f0f2f5" }} />
                      ),
                    },
                  }}
                  rowKey="idMovimiento"
                  pagination={{ pageSize: 10 }}
                  scroll={{ x: "100%" }}
                  bordered
                />
              )}
            </Col>
          </Row>



      </TabPane>
      </Tabs>


      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}  width="70vw" centered>
      <h2 style={{ textAlign: "center", marginBottom: 16 }}>Registrar Nuevo Producto</h2>

      <Row gutter={24}>
        {/* 🔹 Paso en la izquierda */}
        <Col span={6}>
          <Steps current={currentStep} direction="vertical" >
            <Step title="Información General" />
            <Step title="Detalles de Insumos" />
          </Steps>
        </Col>

        {/* 🔹 Contenido del paso en la derecha */}
        <Col span={18}>
          <Form form={form} layout="vertical">
            {/* 🔹 Paso 1: Información General */}
            {currentStep === 0 && (
              <>
              <Form.Item
  name="bodega"
  label="Bodega"
  rules={[{ required: true, message: "Seleccione una bodega" }]}
>
  <Select placeholder="Seleccione una bodega">
    {bodegas.map((bodega) => (
      <Option key={bodega.idBodega} value={bodega.idBodega}>
        {bodega.nombre}
      </Option>
    ))}
  </Select>
</Form.Item>

                <Form.Item name="proveedor" label="Proveedor" rules={[{ required: true, message: "Seleccione un proveedor" }]}>
                  <Select
                    placeholder="Seleccione un proveedor"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Button
                          type="link"
                          icon={<PlusOutlined />}
                          style={{ width: "100%", textAlign: "left", padding: "5px 12px" }}
                          onClick={() => setIsProveedorModalOpen(true)}
                        >
                          Agregar nuevo proveedor
                        </Button>
                      </>
                    )}
                  >
                    {proveedores.map((prov) => (
                      <Option key={prov.idproveedor} value={prov.idproveedor}>
                        {prov.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="numeroReferencia" label="Número de Referencia">
                  <Input placeholder="Ej: 123456" />
                </Form.Item>

                <Form.Item name="fechaIngreso" label="Fecha de Ingreso" rules={[{ required: true, message: "Seleccione la fecha de ingreso" }]}>
                  <DatePicker placeholder="Seleccione la fecha" format="DD-MM-YYYY" style={{ width: "100%" }} />
                </Form.Item>
              </>
            )}

            {/* 🔹 Paso 2: Detalles de los Productos */}
            {currentStep === 1 && (
              <>
                {productos.map((producto, index) => (
              <Row key={index} gutter={[8, 8]} align="middle" style={{ borderBottom: "1px solid #eee", padding: "8px 0" }}>
                {/* Producto */}
               
                <Col span={6}>
                <Typography.Text>Insumo</Typography.Text>
                  <Select
                    placeholder="Seleccionar"
                    value={producto.idProducto}
                    onChange={(value) => handleProductoChange(index, "idProducto", value)}
                    style={{ width: "100%" }}
                  >
                    {insumos.map((insumo) => (
                      <Option key={insumo.idProducto} value={insumo.idProducto}>
                        {insumo.nombre}
                      </Option>
                    ))}
                  </Select>
                </Col>

                {/* Cantidad */}
                <Col span={4}>
                <Typography.Text>Cantidad</Typography.Text>
                  <Input
                    type="number"
                    min="0"
                   
                    value={producto.cantidad}
                    onChange={(e) => handleProductoChange(index, "cantidad", e.target.value)}
                  />
                </Col>

                {/* Precio Unidad */}
                <Col span={4}>
                <Typography.Text>Precio por unidad</Typography.Text>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Precio"
                    value={producto.precioUnidad}
                    onChange={(e) => handleProductoChange(index, "precioUnidad", e.target.value)}
                  />
                </Col>

                {/* Fecha Producción */}
                <Col span={4}>
                <Typography.Text>Fecha Producción</Typography.Text>
                  <DatePicker
                    format="DD-MM-YYYY"
                   
                    style={{ width: "100%" }}
                    onChange={(date) => handleProductoChange(index, "fechaProduccion", date)}
                  />
                </Col>

                {/* Fecha Vencimiento */}
                <Col span={4}>
                <Typography.Text>Fecha Vencimiento</Typography.Text>
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    onChange={(date) => handleProductoChange(index, "fechaVencimiento", date)}
                  />
                </Col>

                {/* Eliminar Producto */}
                <Col span={2} style={{ textAlign: "center" }}>
                  <Button type="link" icon={<DeleteOutlined />} onClick={() => handleRemoveProducto(index)} danger />
                </Col>
              </Row>
            ))}
                <Button type="dashed" onClick={handleAddProducto} block icon={<PlusOutlined />}>
                  Agregar Otro Producto
                </Button>
              </>
            )}
          </Form>
        </Col>
      </Row>

       {/* 🔹 Botones de navegación */}
       <Row justify="end" style={{ marginTop: 16 }}>
        {currentStep > 0 && <Button onClick={handlePrev} style={{ marginRight: 8 }}>Anterior</Button>}
        {currentStep < 1 ? <Button type="primary" onClick={handleNext}>Siguiente</Button> : <Button type="primary" onClick={handleSubmit}>Guardar Registro</Button>}
      </Row>
    </Modal>


     {/* Modal para Agregar Nuevo Proveedor */}
     <Modal
        title="Agregar Nuevo Proveedor"
        visible={isProveedorModalOpen}
        onCancel={() => setIsProveedorModalOpen(false)}
        onOk={handleSubmitProveedor}
        maskClosable={true} // 🔹 Permite cerrar al hacer clic en el fondo oscuro
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} // 🔹 Hace que el fondo se oscurezca
      >
        <Form layout="vertical">
          <Form.Item label="Nombre">
            <Input
              value={newProveedor.nombre}
              onChange={(e) => setNewProveedor({ nombre: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AlmacenesForm;
