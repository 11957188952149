import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ConfigProvider } from "antd"; // 🔹 Importa ConfigProvider de Ant Design
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import Dashboard from "./Dashboard";
import AplicacionesForm from "./AplicacionesForm";
import Configuracion from "./mantenedores/Configuracion";
import Equipo from "./mantenedores/Equipo";
import AplicacionesFormDetails from "./AplicacionesFormDetails";
import Almacenes from "./AlmacenesForm"
import DashboardHome from "./DashboardHome";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#6200EE", // Deep Purple - Color Principal
          colorSecondary: "#EDE7F6", // Teal - Color Secundario
          colorBgBase: "#FFFFFF", // Light Grey - Color de Fondo
          fontFamily: 'Montserrat, sans-serif',
        },
      }}
    >
      <Router>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Rutas protegidas con Dashboard como Layout */}
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >

             {/* Ruta principal actualizada */}
             <Route index element={<DashboardHome />} />
             
            {/* Rutas anidadas dentro del Dashboard */}
            <Route path="" element={<h2>Bienvenido al Dashboard</h2>} />
            <Route path="perfil" element={<h2>Página de Perfil</h2>} />
            <Route path="aplicaciones" element={<AplicacionesForm />} />
            <Route path="almacenes" element={<Almacenes />} />
            <Route path="configuracion" element={<Configuracion />} />
            <Route path="equipo" element={<Equipo />} />
            <Route path="detalle-operacion/:idAplicacion" element={<AplicacionesFormDetails />} />
          </Route>

          {/* Ruta por defecto */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
