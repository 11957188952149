import React from "react";
import { Tabs, Layout, Typography } from "antd";
import MantenedorCampos from "./Campos";

import MantenedorCultivos from "./Cultivos";
import MantenedorProductos from "./Productos";
import MantenedorBodega from "./Bodega"

const { Title } = Typography;
const { TabPane } = Tabs;
const { Header, Content } = Layout;

const Configuracion = () => {
  return (
    <Layout style={{ minHeight: "100vh" , backgroundColor: "#fff"}}>
    

      {/* Contenido principal con Tabs */}
      <Content style={{ padding: "24px" }}>
        <Tabs defaultActiveKey="1" type="card" size="large">
          {/* Pestaña para el Mantenedor de Campos */}
          <TabPane tab="Campos" key="1">
            <MantenedorCampos />
          </TabPane>
          

<TabPane tab="Cultivos" key="2">
  <MantenedorCultivos />
</TabPane>

<TabPane tab="Insumos" key="3">
  <MantenedorProductos />
</TabPane>

<TabPane tab="Bodega" key="4">
  <MantenedorBodega />
</TabPane>

          {/* Otras pestañas (puedes agregar más mantenedores aquí) */}
          {/* 
          <TabPane tab="Mantenedor de Cuarteles" key="2">
            <MantenedorCuarteles />
          </TabPane>
          <TabPane tab="Mantenedor de Cultivos" key="3">
            <MantenedorCultivos />
          </TabPane>
          */}
        </Tabs>
      </Content>
    </Layout>
  );
};

export default Configuracion;
