import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Input, Select, Table, Space, message, Spin, Tag, Dropdown, Menu   } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const MantenedorCampos = () => {
  const userId = localStorage.getItem("userId");
  const [campos, setCampos] = useState([]);
  const [cultivos, setCultivos] = useState([]);
  const [form] = Form.useForm();
  const [idCampo, setIdCampo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cuarteles, setCuarteles] = useState([]);
  const [idCuartel, setIdCuartel] = useState(null);
  const [isCuartelModalOpen, setIsCuartelModalOpen] = useState(false);
  const [formCuartel] = Form.useForm();
  const [campoSeleccionado, setCampoSeleccionado] = useState(null); 

  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

  // Cargar campos
  const fetchCampos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/Service.asmx/GetCampos", {
        headers: { Authorization: token },
        params: { userId },
      });
      setCampos(response.data || []);
    } catch (error) {
      message.error("Error al cargar campos: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

// Cargar cuarteles de un campo
const fetchCuarteles = async (idCampo) => {
  setIsLoading(true);
  try {
    const response = await axios.get("/Service.asmx/GetCuarteles", {
      headers: { Authorization: token },
      params: { idCampo, idUsuario: localStorage.getItem("userId") },
    });
    setCuarteles(response.data || []);
  } catch (error) {
    message.error("Error al cargar cuarteles: " + error.message);
  } finally {
    setIsLoading(false);
  }
};

  // Cargar cultivos disponibles
  const fetchCultivos = async () => {
    try {
      const response = await axios.get("/Service.asmx/GetCultivos", {
        headers: { Authorization: token },
        params: { userId },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
     

      if (Array.isArray(responseData) && responseData.length) {
        setCultivos(responseData);
      } else {
        setErrorMessage("No se encontraron cultivos disponibles.");
      }
    } catch (error) {
      setErrorMessage("Error al cargar los cultivos: " + error.message);
    }
  };

  useEffect(() => {
    fetchCampos();
    fetchCultivos();
  }, []);

  const handleSubmitCampo = async (values) => {

  


    try {
      // Busca el id del cultivo basado en el nombre seleccionado
    const cultivoSeleccionado = cultivos.find(
      (cultivo) => String(cultivo.idCultivo)  // Asegúrate de comparar IDs
    );

 
      const endpoint = idCampo
        ? "/Service.asmx/UpdateCampo"
        : "/Service.asmx/InsertCampo";

      const data = {
        ...values,
        id: idCampo,
        idUsuario: userId,
      };

  

      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data.d
        ? JSON.parse(response.data.d)
        : response.data;

      if (responseData.success) {
        message.success(idCampo ? "Campo actualizado" : "Campo registrado");
        setIsModalOpen(false);
        fetchCampos();
      } else {
        message.error(responseData.message || "Error al guardar el campo.");
      }
    } catch (error) {
      message.error("Error al guardar el campo: " + error.message);
    }
  };

  const handleOpenModal = (campo = null) => {
    if (campo) {
      if (cultivos && cultivos.length > 0) {
        const cultivoSeleccionado = cultivos.find(
          (cultivo) => String(cultivo.idCultivo) === String(campo.idCultivo)
        );
  
      
  
        setIdCampo(campo.idCampo);
        form.setFieldsValue({
          nombre: campo.campo,
          ubicacion: campo.ubicacion,
          area: campo.area,
          unidadarea: campo.unidadarea,
          idCultivo: String(campo.idCultivo), // Asegúrate de que coincida como string
        });
      } else {
        message.error("No se pudieron cargar los cultivos para la edición.");
      }
    } else {
      setIdCampo(null);
      form.resetFields();
    }
    setIsModalOpen(true);
  };
  const calcularAreaTotal = (nuevaArea) => {
    const areaExistente = cuarteles.reduce((total, cuartel) => total + parseFloat(cuartel.area || 0), 0);
    return areaExistente + parseFloat(nuevaArea);
  };

  const handleEditCampo = (campo) => {
    handleOpenModal(campo); // Abre el modal para editar campo
  };
  
   // Abrir modal para editar o agregar cuarteles
   const handleOpenCuartelModal = (campo) => {
    setIdCampo(campo.idCampo);
    fetchCuarteles(campo.idCampo);
    setIsCuartelModalOpen(true);
    setCampoSeleccionado(campo.campo);
  };

   // Guardar o actualizar un cuartel
   const handleSubmitCuartel = async (values) => {
    
    const nuevaAreaTotal = calcularAreaTotal(values.area);
    const endpoint = idCuartel ? "/Service.asmx/UpdateCuartel" : "/Service.asmx/InsertCuartel";
    const data = { ...values, idCuartel, idCampo, idUsuario: userId };
    const areaCampo = parseFloat(campos.find(c => c.idCampo === idCampo)?.area || 0);


 // 🔴 Validación: No permitir que la suma del área de los cuarteles supere el área del campo
 if (nuevaAreaTotal > areaCampo) {
  message.error(`Error: El área total de los cuarteles (${nuevaAreaTotal} ha) no puede superar el área del campo (${areaCampo} ha).`);
  return;
}

    try {
      const response = await axios.post(endpoint, data, {
        headers: { Authorization: token, "Content-Type": "application/json" },
      });
  
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
    
  
      if (responseData.success) {
        message.success(idCuartel ? "Cuartel actualizado" : "Cuartel registrado");
  
        // 🔄 Recargar cuarteles después de actualizar
        fetchCuarteles(idCampo);
  
        // 🔄 Resetear el formulario y el ID después de guardar
        formCuartel.resetFields();
        setIdCuartel(null);
      } else {
        message.error(responseData.message || "Error al guardar el cuartel.");
      }
    } catch (error) {
      message.error("Error al guardar el cuartel: " + error.message);
    }
  };
  

  const columnsCampos = [
    { title: "Nombre", dataIndex: "campo", key: "campo" },
    { title: "Ubicación", dataIndex: "ubicacion", key: "ubicacion" },
    { title: "Área", dataIndex: "area", key: "area" },
    { title: "Cultivo", dataIndex: "cultivo", key: "cultivo" },
    {
      title: "Cuarteles",
      dataIndex: "cuarteles",
      key: "cuarteles",
      render: (cuarteles) => (
        cuarteles
          ? cuarteles.split(" / ").map((cuartel, index) => (
              <Tag key={index}>{cuartel}</Tag> // Tag sin color
            ))
          : <Tag color="default">Sin cuarteles</Tag> // Si es NULL, muestra "Sin cuarteles"
      ),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, campo) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="editCampo" onClick={() => handleEditCampo(campo)}>
                Editar Campo
              </Menu.Item>
              <Menu.Item key="editCuartel" onClick={() => handleOpenCuartelModal(campo)}>
                Agregar/Editar Cuartel
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => handleOpenModal()}
      >
        Nuevo Campo
      </Button>
      <Spin spinning={isLoading}>
        <Table
          className="mt-3"
          dataSource={campos}
          columns={columnsCampos}
          rowKey="idCampo"
        />
      </Spin>

       {/* Modal de Cuarteles */}
       <Modal
         title={`Gestionar Cuarteles - ${campoSeleccionado || "Seleccione un Campo"} (Área: ${campos.find(c => c.idCampo === idCampo)?.area || 0} ha)`}
         visible={isCuartelModalOpen}
        
        onCancel={() => {
          setIsCuartelModalOpen(false);
          fetchCampos(); // 🔄 Actualizar la lista de campos al cerrar
        }}
        footer={null}
        width={800}
      >
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => formCuartel.resetFields()}>
          Agregar Cuartel
        </Button>
         <Spin spinning={isLoading}> 
        <Table
  className="mt-3"
  dataSource={cuarteles}
  columns={[
    { title: "Nombre", dataIndex: "nombre", key: "nombre" },
    { title: "Área", dataIndex: "area", key: "area" },
    { title: "Descripción", dataIndex: "descripcion", key: "descripcion" },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, cuartel) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              
              setIdCuartel(cuartel.idCuartel); // ✅ Guardar ID del cuartel en el estado
              formCuartel.setFieldsValue({
                nombre: cuartel.nombre,
                area: cuartel.area,
                descripcion: cuartel.descripcion || "", // Evitar valores null
              });
            }}
          >
            Editar
          </Button>
        </Space>
      ),
    },
  ]}
  rowKey="idCuartel"
  pagination={{ pageSize: 3 }} // Solo muestra 3 filas por página
  scroll={{ y: 200 }} // Agrega scroll interno a la tabla
/>
 </Spin>


<Form form={formCuartel} layout="vertical" onFinish={handleSubmitCuartel}   className="mt-3">
  <div style={{ display: "flex", gap: "10px" }}>  
    {/* Nombre del Cuartel */}
    <Form.Item name="nombre" label="Nombre del Cuartel" style={{ flex: 1 }} rules={[{ required: true }]}>
      <Input placeholder="Ejemplo: Cuartel Norte" />
    </Form.Item>
    
    {/* Área del Cuartel */}
    <Form.Item name="area" label="Área (ha)" style={{ width: "200px" }} rules={[{ required: true }]}>
      <Input type="number" placeholder="Ejemplo: 10" />
    </Form.Item>
  </div>

  {/* Descripción del Cuartel */}
  <Form.Item name="descripcion" label="Descripción">
  <Input placeholder="Opcional" />
  </Form.Item>

  <Button type="primary" htmlType="submit" block>
    {idCuartel ? "Actualizar Cuartel" : "Registrar Cuartel"}
  </Button>
</Form>
      </Modal>

      <Modal
        title={idCampo ? "Editar Campo" : "Registrar Campo"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmitCampo}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ubicacion"
            label="Ubicación"
            rules={[{ required: true, message: "La ubicación es obligatoria" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="area"
            label="Área"
            rules={[{ required: true, message: "El área es obligatoria" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="unidadarea"
            label="Unidad de Área"
            rules={[{ required: true, message: "La unidad es obligatoria" }]}
          >
            <Select>
              <Option value="ha">Hectáreas</Option>
              <Option value="ac">Acres</Option>
            </Select>
          </Form.Item>
          <Form.Item
  name="idCultivo"
  label="Cultivo"
  rules={[{ required: true, message: "El cultivo es obligatorio" }]}
>
  <Select placeholder="Seleccione un cultivo">
    {cultivos.map((cultivo) => (
      <Option key={cultivo.idCultivo} value={String(cultivo.idCultivo)}>
        {cultivo.nombre}
      </Option>
    ))}
  </Select>
</Form.Item>
          <Button type="primary" htmlType="submit" block>
            {idCampo ? "Actualizar" : "Registrar"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MantenedorCampos;
