import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Input, Table, Space, message, Spin, Avatar,Tag   } from "antd";
import { PlusCircleOutlined, EditOutlined,UserOutlined  } from "@ant-design/icons";

const MantenedorUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [form] = Form.useForm();
  const [idUsuario, setIdUsuario] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";

  // Cargar usuarios
  const fetchUsuarios = async () => {
    const userId = localStorage.getItem("userId");
    setIsLoading(true);
    try {
      const response = await axios.get("/Service.asmx/GetResponsables", {
        headers: { Authorization: token },
        params: { userId },
      });

      console.log(response.data )
      setUsuarios(response.data || []);
    } catch (error) {
      message.error("Error al cargar usuarios: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const handleSubmitUsuario = async (values) => {
    try {
      const endpoint = idUsuario
        ? "/Service.asmx/UpdateUsuario"
        : "/Service.asmx/InsertUsuario";

      const data = {
        ...values,
        idUsuario,
        idCuentaPrincipal: localStorage.getItem("idCuentaPrincipal"), // Asignar automáticamente
      };

      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data.d
        ? JSON.parse(response.data.d)
        : response.data;

      if (responseData.success) {
        message.success(idUsuario ? "Usuario actualizado" : "Usuario registrado");
        setIsModalOpen(false);
        fetchUsuarios();
      } else {
        message.error(responseData.message || "Error al guardar el usuario.");
      }
    } catch (error) {
      message.error("Error al guardar el usuario: " + error.message);
    }
  };

  const handleOpenModal = (usuario = null) => {
    if (usuario) {
      setIdUsuario(usuario.idUsuario);
      form.setFieldsValue({
        nombre: usuario.nombre,
        email: usuario.email,
        telefono: usuario.telefono,
        estado: usuario.estado,
      });
    } else {
      setIdUsuario(null);
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const columnsUsuarios = [
    {
        title: "Usuario",
        key: "usuario",
        render: (_, usuario) => (
          <Space>
            <Avatar icon={<UserOutlined />} />
            <div>
              <div style={{ fontWeight: "bold" }}>{usuario.nombre}</div>
              <div style={{ color: "gray" }}>{usuario.email}</div>
            </div>
          </Space>
        ),
      },
    { title: "Teléfono", dataIndex: "telefono", key: "telefono" },
    {
        title: "Estado",
        key: "estado",
        render: (_, usuario) => (
          <Tag color={usuario.estado ? "green" : "red"}>
            {usuario.estado ? "ACTIVO" : "DESACTIVADO"}
          </Tag>
        ),
      },
    { title: "Fecha Registro", dataIndex: "fecharegistro", key: "fecharegistro" },
    {
      title: "Acciones",
      key: "acciones",
      render: (_, usuario) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleOpenModal(usuario)}
          >
            Editar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => handleOpenModal()}
      >
        Nuevo Usuario
      </Button>
      <Spin spinning={isLoading}>
        <Table
          className="mt-3"
          dataSource={usuarios}
          columns={columnsUsuarios}
          rowKey="idUsuario"
        />
      </Spin>

      <Modal
        title={idUsuario ? "Editar Usuario" : "Registrar Usuario"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmitUsuario}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "El email es obligatorio" },
              { type: "email", message: "Ingrese un email válido" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telefono"
            label="Teléfono"
            rules={[{ required: true, message: "El teléfono es obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            {idUsuario ? "Actualizar" : "Registrar"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MantenedorUsuarios;
