import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { MailOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import Flag from 'react-world-flags'; // Importa la biblioteca de banderas.

const { Option } = Select;

const countryData = [
   { code: 'CL', name: 'Chile', phoneCode: '+56' },
  
];

const Register = () => {
  const onFinish = (values) => {
    console.log('Datos registrados:', values);
    // Redirigir a otra página (simulación)
    window.location.href = '/menu'; // Cambia "/menu" por la ruta real.
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          padding: '30px',
          maxWidth: '400px',
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Registro</h2>
        <Form
          name="register_form"
          layout="vertical"
          onFinish={onFinish}
        >
          {/* Campo: Email */}
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              { required: true, message: 'Por favor, ingresa tu correo electrónico' },
              { type: 'email', message: 'El correo no es válido' },
            ]}
          >
            <Input
              size="large"
              prefix={<MailOutlined />}
              placeholder="Correo electrónico"
            />
          </Form.Item>

          {/* Campo: Nombre */}
          <Form.Item
            name="name"
            label="Nombre completo"
            rules={[{ required: true, message: 'Por favor, ingresa tu nombre completo' }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder="Nombre completo"
            />
          </Form.Item>

          {/* Campo: País y Teléfono */}
          {/* Campo: País y Teléfono */}
          <Form.Item
            name="phone"
            label="Teléfono"
            rules={[
              { required: true, message: 'Por favor, ingresa tu número de teléfono' },
              { pattern: /^[0-9]{7,15}$/, message: 'El teléfono debe ser numérico y válido' },
            ]}
          >
            <Input
              size="large"
              addonBefore={
                <Form.Item name="countryCode" noStyle initialValue="+1">
                  <Select style={{ width: 170 }}>
                    {countryData.map((country) => (
                      <Option key={country.code} value={country.phoneCode}>
                        <Flag
                          code={country.code}
                          style={{
                            width: '25px',
                            height: '15px',
                            marginRight: '8px',
                            borderRadius: '3px',
                          }}
                        />
                        {country.phoneCode} ({country.name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              prefix={<PhoneOutlined />}
              placeholder="Número de teléfono"
            />
          </Form.Item>

          {/* Botón de Enviar */}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Registrarse
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'center' }}>
          <p>
            ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
