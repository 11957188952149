import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form, Select, Input, message, DatePicker, Spin, Switch,Card, Row, Col, Tag,Divider, Steps ,Checkbox,TimePicker, Drawer,Avatar   } from "antd";
import moment from "moment";
import { PlusOutlined, SearchOutlined, FilterOutlined,CloudOutlined, ExperimentOutlined, EnvironmentOutlined, FireOutlined, BarChartOutlined, HourglassOutlined, CheckCircleOutlined } from "@ant-design/icons"; // Ícono predeterminado
import "moment/locale/es"; // Importa el idioma español
import { useNavigate } from "react-router-dom";

 
const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

const AplicacionesForm = () => {

 // Define los títulos de los pasos aquí
 const stepTitles = [
    "Tipo de Operación",
    "Detalles de la Operación",
    "Campos",
    "Insumos",
    "Confirmación",
  ];

 

  // Función para devolver el icono correspondiente según el tipo de aplicación
const getApplicationIcon = (tipo) => {
  switch (tipo) {
    case "Fertilización":
      return <ExperimentOutlined style={{ fontSize: "20px", color: "#6200EE" }} />;
    case "Pulverización":
      return <CloudOutlined style={{ fontSize: "20px", color: "#6200EE" }} />;
    case "Irrigación":
      return <EnvironmentOutlined style={{ fontSize: "20px", color: "#6200EE" }} />;
    case "Plantación":
      return <FireOutlined style={{ fontSize: "20px", color: "#6200EE"}} />;
    default:
      return <ExperimentOutlined style={{ fontSize: "20px", color: "#6200EE" }} />;
  }
};



const navigate = useNavigate(); // Hook para la navegación
  const [aplicaciones, setAplicaciones] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [camposSeleccionados, setCamposSeleccionados] = useState([]);
  const [metodoAplicacion, setMetodoAplicacion] = useState(null);
  const [campos, setCampos] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [filteredAplicaciones, setFilteredAplicaciones] = useState([]); // Datos filtrados
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCampos, setSelectedCampos] = useState([]);
  const [selectedInsumos, setSelectedInsumos] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [selectedResponsable, setSelectedResponsable] = useState(null);
  const [selectedMetodo, setSelectedMetodo] = useState(null);
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
  const [fechaProgramada, setFechaProgramada] = useState(null);
  const [bodegas, setBodegas] = useState([]); // Lista de bodegas
  const [selectedBodega, setSelectedBodega] = useState(null); // Bodega seleccionada

  


  const token = "Bearer 0j2whpl3*s+2/j-hCl!!-5A-8Corubp5[gqzhGrA.b*hBdO[+r.yGIFpvpOqv";


  const fetchInsumos = async () => {
    const userId = localStorage.getItem("userId"); // Obtener userId
    try {
      const response = await axios.get("/Service.asmx/GetProductos", {
        headers: { Authorization: token },
        params: { userId },
      });
  
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setInsumos(responseData || []); // Guardar insumos en el estado
     
    } catch (error) {
      message.error("Error al cargar los insumos: " + error.message);
    }
  };
  

  // 🔹 Cargar bodegas disponibles para el usuario actual
const fetchBodegas = async () => {
  try {
    const userId = localStorage.getItem("userId"); // Obtener idUsuario del almacenamiento
    if (!userId) {
      message.error("Error: No se encontró el ID de usuario.");
      return;
    }

    const response = await axios.get("/Service.asmx/GetBodegas", {
      headers: { Authorization: token },
      params: { userId }, // 🔹 Enviar el ID del usuario como parámetro
    });

    const bodegasData = response.data.d ? JSON.parse(response.data.d) : response.data;
    
    setBodegas(bodegasData);
  } catch (error) {
    message.error("Error al cargar las bodegas: " + error.message);
  }
};

  // Función para cargar responsables
const fetchResponsables = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get("/Service.asmx/GetResponsables", {
        headers: { Authorization: token },
        params: { userId },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setResponsables(responseData || []);
    } catch (error) {
      message.error("Error al cargar los responsables: " + error.message);
    }
  };

  const fetchMetodoAplicacion = async (idTipo) => {
    try {
      const response = await axios.get("/Service.asmx/GetMetodoAplicacion", {
        headers: { Authorization: token },
        params: { idTipo },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      // Asegúrate de que contiene `nombre` y `descripcion`
      setMetodoAplicacion(responseData || null);
    } catch (error) {
      message.error("Error al cargar el método de aplicación: " + error.message);
    }
  };

  // Cargar datos desde la API
  const fetchAplicaciones = async () => {
    setIsLoading(true);
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get("/Service.asmx/GetAplicaciones", {
        headers: { Authorization: token },
        params: { userId },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setAplicaciones(responseData || []);
      setFilteredAplicaciones(responseData || []); // Inicializar datos filtrados
    
    } catch (error) {
      message.error("Error al cargar las aplicaciones: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCampos = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get("/Service.asmx/GetCampos", {
        headers: { Authorization: token },
        params: { userId },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
    
    

      setCampos(responseData || []);
    } catch (error) {
      message.error("Error al cargar los campos: " + error.message);
    }
  };

  const fetchTipos = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get("/Service.asmx/GetTipoAplicaciones", {
        headers: { Authorization: token },
        params: { userId },
      });
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
      setTipos(responseData || []);
    } catch (error) {
      message.error("Error al cargar los tipos de aplicaciones: " + error.message);
    }
  };

  useEffect(() => {
    fetchAplicaciones();
    fetchCampos();
    fetchTipos();
    fetchResponsables();
    fetchInsumos();
    fetchBodegas();
  }, []);


  const isNextDisabled = () => {
    if (currentStep === 0) {
      return !selectedTipo || !selectedMetodo;
    }
    if (currentStep === 2) {
      return camposSeleccionados.length === 0 || camposSeleccionados.some(campo => !campo.idCampo);
    }
    if (currentStep === 3) {
      return selectedInsumos.length === 0 || selectedInsumos.some(insumo => !insumo.idProducto);
    }
    return false;
  };


  
  const handleConfirm = async () => {
    const userId = localStorage.getItem("userId");

    if (!selectedTipo || !selectedMetodo || !selectedResponsable) {
      message.warning("Debe seleccionar un tipo, un método de aplicación y un responsable.");
      return;
    }
    
    if (!selectedTipo || !selectedMetodo || camposSeleccionados.length === 0 || selectedInsumos.length === 0) {
      message.warning("Debe completar todos los pasos antes de confirmar.");
      return;
    }
  
    const data = {
      idUsuario: parseInt(userId, 10),
      fechaProgramada: form.getFieldValue("fechaProgramada")
        ? moment(form.getFieldValue("fechaProgramada")).format("YYYY-MM-DD")
        : null,
      idTipoAplicacion: selectedTipo.idTipoAplicacion,
      idMetodoAplicacion: selectedMetodo.idMetodoAplicacion,
      idResponsable: selectedResponsable ?? 0,
      estado: "P", 
      observaciones: form.getFieldValue("observaciones") || "",
      descripcion: form.getFieldValue("descripcion") || "",
      campos: camposSeleccionados.map((campo) => ({
        idCampo: campo.idCampo,
        cantidadHectareas: parseFloat(campo.hectareas || 0),
        horainicio: campo.horaInicio,
        horafin: campo.horaFin,
      })),
      insumos: selectedInsumos.map((insumo) => ({
        idProducto: insumo.idProducto,
        cantidad: parseFloat(insumo.cantidad || 0),
      })),
    };
 
  
    try {
      const response = await axios.post("/Service.asmx/InsertAplicacion", data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
  
      const responseData = response.data.d ? JSON.parse(response.data.d) : response.data;
  
     


      if (responseData.success) {
        message.success("Aplicación registrada con éxito.");
        fetchAplicaciones(); // Refrescar la lista de aplicaciones
        setIsModalOpen(false);
        form.resetFields();
        setCamposSeleccionados([]);
        setSelectedInsumos([]);
        setSelectedTipo(null);
        setSelectedMetodo(null);
        setCurrentStep(0);
      } else {
        message.error(responseData.message || "Error al guardar la aplicación.");
      }
    } catch (error) {
      message.error("Error al guardar la aplicación: " + error.message);
    }
  };
  const handleAddCampo = () => {
    const newCampo = { idCampo: null, hectareas: "", horaInicio: null, horaFin: null };
    setCamposSeleccionados(prevCampos => [...prevCampos, newCampo]);
  
   
  };


  
  // Eliminar configuración de campo
  const handleRemoveCampo = (index) => {
    setCamposSeleccionados(camposSeleccionados.filter((_, idx) => idx !== index));
  };

  const handleTipoSelect = (tipo) => {
    setSelectedTipo(tipo);
   //setCurrentStep(1);
    setMetodoAplicacion(null); // Resetea el método anterior
    fetchMetodoAplicacion(tipo.idTipoAplicacion);
  };

  const handleMetodoSelect = (metodo) => {
    setSelectedMetodo(metodo); // Establece el método seleccionado
  };

  const handleCamposSelect = (selected) => {
    setSelectedCampos(selected);
    setCurrentStep(2);
  };

  const handleInsumosSelect = (selected) => {
    setSelectedInsumos(selected);
    setCurrentStep(3);
  };

  const handleNext = () => {
    if (currentStep === 0 && (!selectedTipo || !selectedMetodo)) {
      message.warning("Debe seleccionar un tipo de aplicación y método.");
      return;
    }
    if (currentStep === 2 && (camposSeleccionados.length === 0 || camposSeleccionados.some(campo => !campo.idCampo))) {
      message.warning("Debe seleccionar al menos un campo.");
      return;
    }
    if (currentStep === 3) {
      const insumosValidos = selectedInsumos.filter(insumo => insumo.idProducto && insumo.cantidad && insumo.unidadmedida);
      
      if (insumosValidos.length === 0) {
        message.warning("Debe seleccionar al menos un insumo con cantidad y unidad de medida.");
        return;
      }
    }
  
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleAddInsumo = () => {
    setSelectedInsumos([
      ...selectedInsumos,
      { idInsumo: null, unidadMedida: "", cantidad: "" },
    ]);
  };

  const handleRemoveInsumo = (index) => {
    setSelectedInsumos(selectedInsumos.filter((_, idx) => idx !== index));
  };

  const handleInsumoChange = (index, field, value) => {
    setSelectedInsumos(prevInsumos => {
      const updatedInsumos = [...prevInsumos];
      updatedInsumos[index][field] = value;
  
     // Si el usuario selecciona un insumo, asignar la unidad de medida automáticamente
     if (field === "idProducto") {
      const selectedInsumo = insumos.find(insumo => insumo.idProducto === value);
      updatedInsumos[index].unidadmedida = selectedInsumo ? selectedInsumo.unidadmedida : "";
    }
  
    
      return updatedInsumos;
    });
  };

  const handleCampoChange = (index, field, value) => {
    const updatedCampos = [...camposSeleccionados];
    updatedCampos[index][field] = value;
    setCamposSeleccionados(updatedCampos);
  
    // 🔥 También actualizar el estado de selectedCampos
    setSelectedCampos(updatedCampos);
  
    
  };

  // Abrir el modal para crear o editar
  const handleOpenModal = (aplicacion = null) => {
// Restablece los campos del formulario y reinicia el paso actual
form.resetFields(); 
setCurrentStep(0); // Reinicia el paso actual
setSelectedTipo(null); // Limpia el tipo seleccionado
setSelectedMetodo(null); // Limpia el método seleccionado
setCamposSeleccionados([]); // Limpia las configuraciones de campos seleccionados
setSelectedCampos([]); // Limpia los campos seleccionados
setSelectedInsumos([]); // Limpia los insumos seleccionados
setIsModalOpen(true); // Abre el modal



    if (aplicacion) {
      form.setFieldsValue({
        ...aplicacion,
        fechaProgramada: aplicacion.fechaProgramada
        ? moment(aplicacion.fechaProgramada.split("T")[0], "YYYY-MM-DD")
        : null,
      });
    } else {
      form.resetFields();
    }
    setIsModalOpen(true);
  };


  // Función para abrir el Drawer
  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  // Función para cerrar el Drawer
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

   // Aplicar los filtros seleccionados
   const applyFilters = (values) => {
    let filteredData = [...aplicaciones];
    let newFilters = [];
  
    // 🔹 Filtrar por Tipo de Aplicación
    if (values.TipoAplicacion) {
      filteredData = filteredData.filter(
        (aplicacion) => aplicacion.tipoAplicacion === values.TipoAplicacion
      );
      newFilters.push({ key: "Tipo de Aplicación", value: values.TipoAplicacion });
    }
    if (values.Estado) {
      filteredData = filteredData.filter((aplicacion) => aplicacion.estado === values.Estado);
      newFilters.push({ key: "Estado", value: values.Estado });
    }

   

    console.log(filteredData)

    setFilteredAplicaciones(filteredData); // ✅ Actualizar los datos filtrados en la tabla
    setSelectedFilters(newFilters); // ✅ Guardar los filtros aplicados
    closeDrawer(); // ✅ Cierra el Drawer después de aplicar los filtros
  };

  // Eliminar un filtro individual
  const removeFilter = (key) => {
    const updatedFilters = selectedFilters.filter((filter) => filter.key !== key);
    setSelectedFilters(updatedFilters);
  
    let filteredData = [...aplicaciones];
  
    updatedFilters.forEach((filter) => {
      if (filter.key === "Tipo de Aplicación") {
        filteredData = filteredData.filter(
          (aplicacion) => aplicacion.tipoAplicacion === filter.value
        );
      }
      
    });
  
    setFilteredAplicaciones(filteredData);
  };

  // Limpiar todos los filtros
  const clearAllFilters = () => {
    setSelectedFilters([]); // 🔥 Vaciar la lista de filtros
    setFilteredAplicaciones(aplicaciones); // 🔥 Restaurar los datos originales
  };


  const handleSearch = (value) => {
  
    setSearchTerm(value); // Actualiza el estado del término de búsqueda
    const lowercasedValue = value.toLowerCase();
  
    // Define los campos relevantes para la búsqueda
    const searchableFields = ["campo", "tipoAplicacion", "estado", "observaciones"];
  
    const filteredData = aplicaciones.filter((aplicacion) =>
      searchableFields.some((key) => 
        aplicacion[key]?.toString().toLowerCase().includes(lowercasedValue)
      )
    );
  
    setFilteredAplicaciones(filteredData); // Actualiza los datos filtrados
  };
  // Estadísticas
  const total = aplicaciones.length;
  const pendientes = aplicaciones.filter((app) => app.estado === "PENDIENTE").length;
  const completadas = aplicaciones.filter((app) => app.estado === "COMPLETADO").length;

  const columns = [
    {
      title: "Aplicación",
      dataIndex: "tipoAplicacion",
      key: "tipoAplicacion",
      render: (tipo, record) => (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {/* Columna del Avatar */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
            <Avatar
              size={50} // Tamaño ajustable del avatar
              style={{ backgroundColor: "#EDE7F6", color: "#6200EE" }}
            >
              {getApplicationIcon(tipo)}
            </Avatar>
          </div>
    
          {/* Columna del Tipo de Aplicación y Método */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>{tipo}</span>
            <span style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}>
              {record.metodoaplicacion}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Campo",
      dataIndex: "campocultivo", // Campo donde viene la cadena separada por "|"
      key: "campocultivo",
      render: (campocultivo) => {
        // Validar si el valor existe
        if (!campocultivo) return <span style={{ color: "gray" }}>-</span>;
    
        // Convertir la cadena en un array
        const campos = campocultivo.split(" | ").map((campo) => campo.trim());
    
        return (
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", alignItems: "center" }}>
            {/* Mostrar los primeros 2 campos */}
            {campos.slice(0, 2).map((campo, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  padding: "4px 8px",
                 
                }}
              >
              
              <span style={{ fontWeight: "bold" }}>{campo}</span>
              </div>
            ))}
    
            {/* Mostrar el signo +n si hay más de 2 campos */}
            {campos.length > 2 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  
                  borderRadius: "8px",
                  color: "#595959",
                  fontWeight: "bold",
                  padding: "4px 8px",
                  border: "1px solid #d9d9d9",
                }}
              >
                +{campos.length - 2}
              </div>
            )}
          </div>
        );
      },
    },
    
    { title: "Fecha Programada", dataIndex: "fechaProgramada", key: "fechaProgramada", 

      render: (fecha) => (
        <span style={{ fontWeight: "bold" }}>
          {fecha}
        </span>
      ),
    },
    {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (estado) => (
          <Tag color={estado === "PENDIENTE" ? "gold" : "green"}  style={{  border: "none",}}> 
            {estado}
          </Tag>
        ),
      },
      {
        title: "Insumos",
        dataIndex: "insumos", // Nombre del campo en los datos
        key: "insumos",
        render: (insumos) => {
          // Validar si el valor existe
          if (!insumos) return <span style={{ color: "gray" }}>-</span>;
      
          // Convertir la cadena en un array (si los datos vienen como string separados por '|')
          const insumosArray = insumos.split(" | ").map((insumo) => insumo.trim());
      
          return (
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", alignItems: "center" }}>
              {/* Mostrar los primeros 2 insumos */}
              {insumosArray.slice(0, 2).map((insumo, index) => (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    border: "1px solid #d9d9d9",
                    borderRadius: "8px",
                    padding: "4px 8px",
                 
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{insumo}</span>
                </div>
              ))}
      
              {/* Mostrar el signo +N si hay más de 2 insumos */}
              {insumosArray.length > 2 && (
                <div
                  style={{
                    display: "inline-block",
                    borderRadius: "8px",
                    padding: "4px 8px",
                  
                    color: "#595959",
                    fontWeight: "bold",
                  }}
                >
                  +{insumosArray.length - 2}
                </div>
              )}
            </div>
          );
        },
      },
    { title: "Responsable", dataIndex: "responsables", key: "responsables" ,

      render: (responsable) => (
        <span style={{ fontWeight: "bold" }}>
          {responsable}
        </span>
      ),
    },
    
    
  ];

  return (
    <div className="container">




<Row justify="space-between" align="middle">
    {/* Columna para el título */}
    <Col>
    <h1 className="mb-4">Operaciones Periodicas</h1>
    </Col>

    {/* Columna para el botón */}
    <Col>
      <Button type="primary"   size="large" icon={<PlusOutlined />} onClick={() => handleOpenModal()}>
        Nueva Operacion Periodica
      </Button>
    </Col>
  </Row>

      
  <Row justify="end" align="middle" gutter={16} style={{ width: "100%" }}>
      {/* Input de búsqueda */}
      <Col flex="1">
        <Input
          size="large"
          placeholder="Buscar..."
          prefix={<SearchOutlined />}
          style={{ width: "250px", borderRadius: "13px"}} 
          onChange={(e) => handleSearch(e.target.value)} // Manejar la búsqueda
        />
      </Col>

       {/* Mostrar los filtros seleccionados */}
       <Col>
          {selectedFilters.map((filter) => (
            <Tag
            key={filter.key}
            closable
            onClose={() => removeFilter(filter.key)}
              color="#6200EE"
            style={{
              marginBottom: "8px",
              fontSize: "12px",
              borderRadius: "20px",
              padding: "4px 12px",
              display: "inline-flex",
              alignItems: "center",
            }}


            
          >
            {`${filter.key}: `}
              <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
                {filter.value}
              </span>
          </Tag>
          ))}

          {/* Botón para limpiar todos los filtros */}
          {selectedFilters.length > 0 && (
            <Button size="small" type="link" onClick={clearAllFilters}  style={{ fontWeight: "bold" , color:"#6200EE"}}>
              Eliminar Filtros
            </Button>
          )}
        </Col>

      {/* Botón de filtro */}
      <Col>
        <Button
          type="primary"
          size="large"
          icon={<FilterOutlined />}
          onClick={showDrawer} // Mostrar el Drawer al hacer clic
        >
          Filtros
        </Button>
      </Col>
    </Row>
  <Row style={{ marginTop: "34px" }} />

      {/* Panel de estadísticas */}
      
      
<Row gutter={[20, 20]}>
  {/* Total */}
  <Col span={8}>
    <Card
     
      style={{
        borderRadius: 26,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        padding: "1px",
        height:"100px",
        boxShadow:false,
      }}
    >
      <Row align="middle">
        {/* Columna del Ícono */}
        <Col span={6}>
          <div
            style={{
              backgroundColor: "#EDE7F6",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "48px",
              height: "48px",
            }}
          >
            <BarChartOutlined  ined style={{ fontSize: "24px",  color: "#6200EE" }} />
          </div>
        </Col>

        {/* Columna del Título y Valor */}
        <Col span={18}>
          <h3 style={{ margin: 0, fontSize: "14px", color: "#333" }}>Total</h3>
          <h2 style={{ margin: 0, fontSize: "24px",  }}>
          {isLoading ? <Spin size="small" /> : total}


          </h2>
        </Col>
      </Row>
    </Card>
  </Col>

   {/* Pendientes */}
   <Col span={8}>
    <Card
    
      style={{
        borderRadius: 26,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        padding: "1px",
        height:"100px",
        boxShadow:false,
      }}
      
    >
      <Row align="middle">
        {/* Columna del Ícono */}
        <Col span={6}>
          <div
            style={{
              backgroundColor: "#EDE7F6",

              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "48px",
              height: "48px",
            }}
          >
            <HourglassOutlined style={{ fontSize: "24px",  color: "#6200EE" }} />
          </div>
        </Col>

        {/* Columna del Título y Valor */}
        <Col span={18}>
          <h3 style={{ margin: 0, fontSize: "14px", color: "#333" }}>Pendientes</h3>
          <h2 style={{ margin: 0, fontSize: "24px",  }}>
          {isLoading ? <Spin size="small" /> : pendientes}
            </h2>
        </Col>
      </Row>
    </Card>
  </Col>

  {/* Completadas */}
  <Col span={8}>
    <Card
     
      style={{
        borderRadius: 26,
        boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.1)",
        padding: "1px",
        height:"100px",
        boxShadow:false,
      }}
    >
      <Row align="middle">
        {/* Columna del Ícono */}
        <Col span={6}>
          <div
            style={{
              backgroundColor: "#EDE7F6",

              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "48px",
              height: "48px",
            }}
          >
            <CheckCircleOutlined style={{ fontSize: "24px",   color: "#6200EE" }} />
          </div>
        </Col>

        {/* Columna del Título y Valor */}
        <Col span={18}>
          <h3 style={{ margin: 0, fontSize: "14px", color: "#333" }}>Completadas</h3>
          <h2 style={{ margin: 0, fontSize: "24px",  }}>
          {isLoading ? <Spin size="small" /> : completadas}
            </h2>
        </Col>
      </Row>
    </Card>
  </Col>
</Row>

{/* Espaciado adicional */}
<Row style={{ marginTop: "34px" }} />


      {/* Tabla de aplicaciones */}
      <div className="mt-4">

        
     
        <Spin spinning={isLoading}>
          <Table
            borderRadius= "26px"
            className="mt-3"
            dataSource={filteredAplicaciones} // Usa los datos filtrados
            columns={columns}
            rowKey="idAplicacion"
            pagination={{ pageSize: 10 }}
            size="small" 
            onRow={(record) => ({
              onClick: () => navigate(`/dashboard/detalle-operacion/${record.idAplicacion}`),
              style: { cursor: "pointer" }, // 🔥 Hace que toda la fila tenga cursor tipo mano
             
            })}
            components={{
              header: {
                cell: (props) => (
                  <th {...props} style={{ fontSize: "16px", padding: "18px", height: "50px", background: "#f0f2f5" }} />
                ),
              },
            }}
          />
        </Spin>


      </div>




       {/* Drawer para los filtros */}
       <Drawer
        title="Filtros Avanzados"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerOpen}
        width={400}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={applyFilters} // Aplica los filtros seleccionados
        >
         <Form.Item label="Tipo de Aplicación" name="TipoAplicacion">
      <Select placeholder="Seleccione un tipo">
        {tipos.map((tipo) => (
          <Option key={tipo.idTipoAplicacion} value={tipo.nombre}>
            {tipo.nombre}
          </Option>
        ))}
      </Select>
    </Form.Item>

    <Form.Item label="Estado" name="Estado">
            <Select placeholder="Seleccione un estado">
              <Option value="PENDIENTE">Pendiente</Option>
              <Option value="COMPLETADO">Completado</Option>
            </Select>
          </Form.Item>

          {/* Botón para aplicar los filtros */}
          <Button type="primary" block htmlType="submit">
            Aplicar Filtros
          </Button>
        </Form>
      </Drawer>






      {/* Modal de registro/edición */}
      <Modal
  visible={isModalOpen}
  onCancel={() => setIsModalOpen(false)}
  footer={null}
  width={1200}
>
    {/* Título personalizado */}
  <div style={{ marginBottom: 16, borderBottom: "1px solid #f0f0f0", paddingBottom: 8 }}>
    <h2 style={{ margin: 0 }}>Nueva Operacion Periodica</h2>
  </div>

<Row gutter={16}>
 {/* Pasos con estilo de Avatar */}
 <Col span={6}>
            <Steps current={currentStep} direction="vertical">
              {stepTitles.map((title, index) => (
                <Step
                  key={index}
                  title={title}
                  icon={
                    <div
                      style={{
                        backgroundColor: currentStep === index ? "#6200EE" : "#EDE7F6",
                        color: currentStep === index ? "#ffffff" : "#6200EE",
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1}
                    </div>
                  }
                />
              ))}
            </Steps>
          </Col>

  <Col span={18}>
 {/* Título dinámico del paso */}
 <h3 style={{ marginBottom: 16, fontSize: "25px", fontWeight: "bold", textAlign: "left" }}>
    {stepTitles[currentStep]}
  </h3>
  <div style={{ marginTop: 24 }}>
    {/* Paso 0: Seleccionar Tipo de Aplicación */}
    {currentStep === 0 && (
      <>
        <Row gutter={[26, 6]}>
            
          {/* Mostrar los tipos de aplicación */}
          {tipos.map((tipo) => (
            <Col span={5} key={tipo.idTipoAplicacion}>
                
              <Card
              
                style={{
                    width: "75%", // Ancho del Card
                    maxWidth: "190px", // Ancho máximo para todas las tarjetas
                    minWidth: "170px", // Ancho mínimo para garantizar un diseño uniforme
                    textAlign: "left",
                    height: "100%",
                    border: selectedTipo?.idTipoAplicacion === tipo.idTipoAplicacion ? "2px solid #EDE7F6" : "1px solid #f0f0f0",
                  borderRadius: 26,
                  cursor: "pointer", // Muestra la mano al pasar el mouse
                }}
                onClick={() => handleTipoSelect(tipo)}
              >
               
                    {/* Ícono del tipo de aplicación */}
      <div
        style={{
         backgroundColor: "#EDE7F6",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          width: "48px",
          height: "48px",
          margin: "0 auto 10px", // Centrar y agregar espacio inferior
        }}
      >
        {getApplicationIcon(tipo.nombre)}
      </div>
      <h4
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "#333",
          marginBottom: "8px",
        }}
      >
        {tipo.nombre}
      </h4>
                <p
          style={{
            fontSize: "14px", // Tamaño de fuente
            color: "#777", // Color de texto
            lineHeight: "1.5", // Altura de línea para mejor lectura
            margin: 0, // Elimina márgenes adicionales
            textAlign: "left",
          }}
        >
          {tipo.descripcion}
        </p>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Mostrar los métodos de aplicación debajo del tipo seleccionado */}
        {selectedTipo && (
          <>
            <h3 style={{ marginTop: 14 }}>Métodos de Aplicación</h3>
            {isLoading ? (
              <Spin tip="Cargando métodos de aplicación..." />
            ) : metodoAplicacion ? (
              <Row gutter={[16, 16]}>
                {Array.isArray(metodoAplicacion) ? (
                  metodoAplicacion.map((metodo, index) => (
                    <Col span={5} key={metodo.id}>
                      <Card
                        
                        style={{
                            width: "75%", // Ancho del Card
                            maxWidth: "190px", // Ancho máximo para todas las tarjetas
                            minWidth: "180px", // Ancho mínimo para garantizar un diseño uniforme
                          textAlign: "left",
                          height: "100%",
                          border: selectedMetodo?.idMetodoAplicacion === metodo.idMetodoAplicacion ? "2px solid #EDE7F6" : "1px solid #f0f0f0",
                          borderRadius: 26,
                          cursor: "pointer", // 🔹 Muestra la mano sin el efecto hover de sombra
                        }}
                        onClick={() => handleMetodoSelect(metodo)}
                      >
                        <h4>{metodo.nombe}</h4>
                         <p
          style={{
            fontSize: "13px", // Tamaño de fuente
            color: "#777", // Color de texto
            lineHeight: "1.5", // Altura de línea para mejor lectura
            margin: 0, // Elimina márgenes adicionales
          }}
        >{metodo.descripcion}</p>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <Card
                      hoverable
                      style={{
                        textAlign: "left",
                        border: selectedMetodo?.id === metodoAplicacion.id ? "2px solid #EDE7F6" : "1px solid #f0f0f0",
                        borderRadius: 6,
                      }}
                      onClick={() => handleMetodoSelect(metodoAplicacion)}
                    >
                      <h4>{metodoAplicacion.nombe}</h4>
                      <p>{metodoAplicacion.descripcion}</p>
                    </Card>
                  </Col>
                )}
              </Row>
            ) : (
              <p style={{ textAlign: "center", marginTop: 16 }}>
                
              </p>
            )}
          </>
        )}
      </>
    )}

     {/* Paso 3: Detalles de la Aplicación */}
     {currentStep === 1 && (
      <Form form={form} layout="vertical"  style={{ marginTop: 16 }}>
 <Form.Item
      name="descripcion"
      label="Descripción"
      rules={[{ required: true, message: "Ingrese una descripción" }]}
    >
      <Input placeholder="Ingrese la descripción" />
    </Form.Item>
    <Form.Item
      name="responsable"
      label="Responsable"
      rules={[{ required: true, message: "Seleccione un responsable" }]}
    >
      <Select placeholder="Seleccione un responsable"  onChange={(value) => setSelectedResponsable(value)} >
        {responsables.map((responsable) => (
          <Option key={responsable.id} value={responsable.id}>
            {responsable.nombre}
          </Option>
        ))}
      </Select>
    </Form.Item>


        <Form.Item
          name="fechaProgramada"
          label="Fecha Programada"
          rules={[{ required: true, message: "Seleccione una fecha programada" }]}
        >
          
          <DatePicker  placeholder="Seleccione la fecha" format="DD-MM-YYYY" style={{ width: "100%" }} 
           
          />
          
        </Form.Item>

        <Form.Item name="observaciones" label="Observaciones">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    )}

    {/* Paso 1: Seleccionar Campos */}
{/* Paso 1: Seleccionar Campos */}
{currentStep === 2 && (
  <>
{camposSeleccionados.map((campo, index) => {
  const selectedCampo = campos.find(c => c.idCampo === campo.idCampo) || {};
  const tieneCuarteles = campo.cuarteles?.length > 0;

  return (
    <Card
      key={index}
      style={{
        marginBottom: 16,
        border: "1px solid #d9d9d9",
        borderRadius: 8,
        padding: 16,
      }}
    >
      <Row gutter={16}>
        {/* Seleccionar Campo */}
        <Col span={6}>
          <Select
            placeholder="Seleccione un campo"
            value={campo.idCampo}
            onChange={async (value) => {
              handleCampoChange(index, "idCampo", value);
              handleCampoChange(index, "hectareas", 0);
              handleCampoChange(index, "modoAplicacion", "campo"); // Por defecto: Todo el campo
              handleCampoChange(index, "cuartelesSeleccionados", []); // Limpiar cuarteles

              // 🔥 Llamar al servicio para obtener los cuarteles de este campo
              const response = await axios.get("/Service.asmx/GetCuarteles", {
                headers: { Authorization: token },
                params: { idCampo: value, idUsuario: localStorage.getItem("userId") },
              });

              // Guardar los cuarteles en el estado del campo
              handleCampoChange(index, "cuarteles", response.data || []);
            }}
            style={{ width: "100%" }}
          >
            {campos.map((campo) => (
              <Option key={campo.idCampo} value={campo.idCampo}>
                {campo.campo} ({campo.area} {campo.unidadarea})
              </Option>
            ))}
          </Select>
        </Col>

        {/* Cantidad de Hectáreas */}
        <Col span={4}>
          <Input
            placeholder="Hectáreas"
            value={campo.hectareas}
            onChange={(e) => {
              const inputValue = parseFloat(e.target.value) || 0;
              const maxHectareas = selectedCampo.area || 0;

              if (inputValue > maxHectareas) {
                message.error(`No puedes ingresar más de ${maxHectareas} ${selectedCampo.unidadarea}`);
              } else {
                handleCampoChange(index, "hectareas", inputValue);
              }
            }}
            type="number"
            min="0"
          />
        </Col>

        {/* Hora de Inicio */}
        <Col span={6}>
          <TimePicker
            placeholder="Hora Inicio"
            value={campo.horaInicio ? moment(campo.horaInicio, "HH:mm") : null}
            onChange={(time, timeString) => handleCampoChange(index, "horaInicio", timeString)}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </Col>

        {/* Hora de Fin */}
        <Col span={6}>
          <TimePicker
            placeholder="Hora Fin"
            value={campo.horaFin ? moment(campo.horaFin, "HH:mm") : null}
            onChange={(time, timeString) => handleCampoChange(index, "horaFin", timeString)}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </Col>

        {/* Botón Eliminar */}
        <Col span={2}>
          <Button danger onClick={() => handleRemoveCampo(index)}>
            Eliminar
          </Button>
        </Col>
      </Row>

     
    
    </Card>
  );
})}

    {/* Botón para agregar nueva configuración */}
    <Button type="dashed" onClick={handleAddCampo} block>
      Agregar Campo
    </Button>
  </>
)}

{currentStep === 3 && (
  <>
    {selectedInsumos.map((insumo, index) => (
      <Card
        key={index}
        style={{
          marginBottom: 16,
          border: "1px solid #d9d9d9",
          borderRadius: 8,
          padding: 16,
        }}
      >
        <Row gutter={16}>
          {/* Seleccionar Insumo */}
          <Col span={8}>
            <Select
              placeholder="Seleccione un insumo"
              value={insumo.idProducto}
              onChange={(value) => handleInsumoChange(index, "idProducto", value)}
              style={{ width: "100%" }}
            >
              {insumos.map((insumo) => (
                <Option key={insumo.idProducto} value={insumo.idProducto}>
                  {insumo.nombre}
                </Option>
              ))}
            </Select>
          </Col>
           {/* Seleccionar Bodega */}
           <Col span={6}>
            <Select
              placeholder="Seleccione una bodega"
              value={insumo.idBodega}
              onChange={(value) => handleInsumoChange(index, "idBodega", value)}
              style={{ width: "100%" }}
            >
              {bodegas.map((bodega) => (
                <Option key={bodega.idBodega} value={bodega.idBodega}>
                  {bodega.nombre}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Mostrar Unidad de Medida */}
          <Col span={3}>
            <Input
              value={insumo.unidadmedida || ""}
              disabled
              placeholder="Unidad"
            />
          </Col>

          {/* Cantidad por Hectárea */}
          <Col span={3}>
            <Input
              placeholder="Cantidad por hectárea"
              value={insumo.cantidad}
              onChange={(e) =>
                handleInsumoChange(index, "cantidad", e.target.value)
              }
              type="number"
              min="0"
            />
          </Col>

          {/* Botón Eliminar */}
          <Col span={4}>
            <Button danger onClick={() => handleRemoveInsumo(index)}>
              Eliminar
            </Button>
          </Col>
        </Row>
      </Card>
    ))}

    {/* Botón para agregar más insumos */}
    <Button type="dashed" onClick={handleAddInsumo} block>
      Agregar Insumo
    </Button>
  </>
)}

   

    {/* Paso 4: Confirmación */}
    {currentStep === 4 && (
  <div style={{ padding: "20px" }}>
    <h3 style={{ marginBottom: "12px", textAlign: "center" }}>Resumen de la Operación</h3>

    {/* 🔹 Tipo de Aplicación y Método */}
    <Card style={{ marginBottom: "16px", borderRadius: "12px", backgroundColor: "#FAFAFA" }}>
      <Row align="middle">
        <Col span={3}>
          <Avatar size={50} style={{ backgroundColor: "#EDE7F6", color: "#6200EE" }}>
            {getApplicationIcon(selectedTipo?.nombre)}
          </Avatar>
        </Col>
        <Col span={21}>
          <h4 style={{ marginBottom: 0 }}>{selectedTipo?.nombre || "No seleccionado"}</h4>
          <Tag color="purple">{selectedMetodo?.nombe || "No seleccionado"}</Tag>
        </Col>
      </Row>
    </Card>

    {/* 🔹 Detalles de la Operación */}
    <Card style={{ marginBottom: "16px", borderRadius: "12px", backgroundColor: "#FAFAFA" }}>
      <h4>Detalles de la Operación</h4>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <span><strong>Descripción:</strong> {form.getFieldValue("descripcion") || "No ingresado"}</span>
        <span><strong>Responsable:</strong> {responsables.find(r => r.id === selectedResponsable)?.nombre || "No seleccionado"}</span>
        <span><strong>Fecha Programada:</strong> {form.getFieldValue("fechaProgramada")?.format("DD-MM-YYYY") || "No ingresado"}</span>
        <span><strong>Observaciones:</strong> {form.getFieldValue("observaciones") || "Sin observaciones"}</span>
      </div>
    </Card>

    {/* 🔹 Campos Seleccionados */}
    <Card style={{ marginBottom: "16px", borderRadius: "12px", backgroundColor: "#FAFAFA" }}>
      <h4>Campos Seleccionados</h4>
      {camposSeleccionados.length === 0 ? (
        <span>No se han seleccionado campos.</span>
      ) : (
        camposSeleccionados.map((campo, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "4px" }}>
            <Tag color="blue">{campos.find(c => c.idCampo === campo.idCampo)?.campo || "No seleccionado"}</Tag>
            <span>Hectáreas: {campo.hectareas || "0"} Ha</span>
            <span>Horario: {campo.horaInicio} - {campo.horaFin}</span>
          </div>
        ))
      )}
    </Card>

    {/* 🔹 Insumos Seleccionados */}
    <Card style={{ marginBottom: "16px", borderRadius: "12px", backgroundColor: "#FAFAFA" }}>
      <h4>Insumos Seleccionados</h4>
      {selectedInsumos.length === 0 ? (
        <span>No se han agregado insumos.</span>
      ) : (
        selectedInsumos.map((insumo, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "4px" }}>
            <Tag color="green">{insumos.find(i => i.idProducto === insumo.idProducto)?.nombre || "No seleccionado"}</Tag>
            <span>Cantidad: {insumo.cantidad} {insumo.unidadmedida}</span>
            <span>Bodega: {bodegas.find(b => b.idBodega === insumo.idBodega)?.nombre || "No seleccionada"}</span>
          </div>
        ))
      )}
    </Card>

    {/* 🔹 Botón de Confirmar */}
    <div style={{ textAlign: "center", marginTop: "16px" }}>
      <Button type="primary" onClick={handleConfirm}>
        Confirmar Operación
      </Button>
    </div>
  </div>
)}
  </div>

  {/* Controles de Navegación */}
  <div style={{ marginTop: 16, textAlign: "right" }}>
    {currentStep > 0 && <Button onClick={handlePrev} style={{ marginRight: 8 }}>Anterior</Button>}
    {currentStep < 4 && (
      <Button
        type="primary"
        onClick={handleNext}
        disabled={isNextDisabled()}
        
      >
         Siguiente: {stepTitles[currentStep + 1]}
      </Button>
    )}
  </div>
  </Col>
  </Row>
</Modal>
    </div>
  );
};

export default AplicacionesForm;
