import React, { useState } from 'react';
import { Layout, Menu, Avatar, Dropdown, Badge, List, Popover, Divider } from 'antd';
import { Outlet, Link } from 'react-router-dom';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  SettingOutlined,
  DatabaseOutlined,
  ShopOutlined,
  BellOutlined,RocketOutlined
} from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // Ejemplo de avisos rápidos
  const notifications = [
     
  ];

  // Estilo mejorado para el listado
  const notificationList = (
    <List
      style={{ width: '280px', maxHeight: '300px', overflowY: 'auto' }}
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            avatar={<BellOutlined style={{ fontSize: '18px', color: '#1890ff' }} />}
            title={<span style={{ fontWeight: '500' }}>{item.content}</span>}
            description={<span style={{ fontSize: '12px' }}>{item.date}</span>}
          />
        </List.Item>
      )}
    />
  );

  const userMenu = (
    <Menu
      items={[
        {
          key: 'logout',
          label: 'Cerrar Sesión',
          onClick: () => {
            localStorage.removeItem('isAuthenticated');
            window.location.href = '/login';
          },
        },
      ]}
    />
  );
  
  const menuItems = [
    {
      key: '1',
      icon: <HomeOutlined style={{ fontSize: '20px' }} />, 
      label: <Link to="/dashboard">Inicio</Link>,
    },
    {
      key: '2',
      icon: <RocketOutlined style={{ fontSize: '20px' }}/>,
      label: <Link to="/dashboard/guia-inicio">Guía de Inicio</Link>, // 🔥 Nuevo Menú
    },
    {
      key: '4',
      icon: <DatabaseOutlined style={{ fontSize: '20px' }}/>, 
      label: <Link to="/dashboard/aplicaciones">Operaciones Periódicas</Link>,
    },
    {
      key: '5',
      icon: <ShopOutlined style={{ fontSize: '20px' }}/>, 
      label: "Gestión de Inventario",
      children: [
        {
          key: "5-1",
          icon: <ShopOutlined style={{ fontSize: '20px' }}/>, 
          label: <Link to="/dashboard/almacenes">Almacenes</Link>,
        },
      ],
    },
    {
      key: "3",
      icon: <SettingOutlined style={{ fontSize: '20px' }} />, 
      label: "Configuración",
      children: [
        {
          key: "3-1",
          icon: <SettingOutlined style={{ fontSize: '20px' }}/>, 
          label: <Link to="/dashboard/configuracion">Ajustes</Link>,
        },
        {
          key: "3-2",
          icon: <UserOutlined style={{ fontSize: '20px' }}/>, 
          label: <Link to="/dashboard/equipo">Equipo</Link>,
        },
      ],
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
        collapsedWidth={80}
        style={{
          background: '#fff',
          borderRight: '1px solid #ddd',
          position: 'fixed',
          height: '100vh',
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out'
        }}
      >
        <div
          style={{
            height: '74px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          {collapsed ? 'Logo' : 'Mi Dashboard'}
        </div>
        <Menu mode="inline" items={menuItems} style={{ background: '#fff', fontSize: "15px" }} />
      </Sider>

      <Layout style={{ marginLeft: collapsed ? 80 : 250, transition: 'margin-left 0.3s ease-in-out' }}>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 25px',
            background: '#fff',
            borderBottom: '1px solid #ddd',
            position: 'fixed',
            width: `calc(100% - ${collapsed ? 80 : 250}px)`,
            zIndex: 1000,
            transition: 'width 0.3s ease-in-out'
          }}
        >
          <div>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggleCollapse,
              style: { fontSize: '18px', cursor: 'pointer' },
            })}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
            <Popover
              placement="bottomRight"
              title={<span style={{ fontWeight: '600' }}>Notificaciones</span>}
              content={notificationList}
              trigger="click"
              
            >
              <Badge count={notifications.length}>
                <BellOutlined
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    padding: '8px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '50%',
                  }}
                />
              </Badge>
            </Popover>

            <Divider type="vertical" style={{ height: '30px' }} />

            <Dropdown overlay={userMenu}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Avatar size="large" icon={<UserOutlined />} />
                <span style={{ marginLeft: '10px' }}>Nombre del Usuario</span>
              </div>
            </Dropdown>
          </div>
        </Header>

        <Content
          style={{
            marginTop: 64,
            padding: '20px',
            background: '#f0f2f5',
            minHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
